import React, { Suspense } from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Router from "./route/router";
import * as Sentry from "@sentry/react";
import { Image } from 'antd';

// Sentry.init({
//   dsn: "https://7693cb9ba7136bedec4e8fe57b73f5c2@o4508404858748928.ingest.us.sentry.io/4508409962430464",
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration(),
//   ],
//   // Tracing
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

if (process.env.NODE_ENV === "production") {
  console.log = () => { };
}

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register(`/firebase-messaging-sw.js`)
    .then((registration) => {
      console.log("Service Worker registered with scope:", registration.scope);
    })
    .catch((error) => {
      Sentry.captureException(error);
      console.error('Service Worker registration failed:', error);
    });
}

// const loadingMarkup = (
//   <div
//     style={{
//       width: "100%",
//       height: "100vh",
//       display: "flex",
//       justifyContent: "center",
//       alignItems: "center",
//     }}
//   >
//     <div style={{ position: "relative" }}>
//       <div className="loader"></div>
//       <div
//         style={{
//           width: 80,
//           height: 80,
//           position: "absolute",
//           left: 10,
//           top: 10,
//         }}
//       ></div>
//     </div>
//   </div>
// );

const loadingImage = require('./assets/zeimm_favicon_theme.png')
const loadingMarkup = (
  <div style={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <div style={{ position: 'relative' }}>
      <div className="loader"></div>
      <div style={{
        width: 80,
        height: 80,
        position: 'absolute',
        left: 10,
        top: 10
      }}>
        <Image
          src={loadingImage}
          preview={false}
          style={{ marginTop: 10, marginLeft: 10, }}
          width={60}
        />
      </div>
    </div>
  </div>
)


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Suspense fallback={loadingMarkup}>
      <Router />
    </Suspense>
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
