import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import App, { useUserInfo } from '../App';
import { getPermission, getUserInfo } from '../core/auth';
import ChargingStationForm from '../page/charging-station/charging-station-form';
import ChargingStationMap from '../page/charging-station/charging-station-map';
import ChargingStationPage from '../page/charging-station/charging-station-page';
import ChargingStationUpdate from '../page/charging-station/charging-station-update';
import DriverPoints from '../page/driver-points-management/driver-points';
import DriverVehicleAssignWithCalendarV2 from '../page/driver-vehicle-assign/driver-vehicle-assign-with-calendar-v2';
import FaqForm from '../page/faq/faq-form';
import FaqPage from '../page/faq/faq-page';
import FaqUpdate from '../page/faq/faq-update';
import LoginPage from '../page/login/login-page';
import Testing from '../page/map/Testing';
import Testing2 from '../page/map/Testing2';
import NotificationChannelForm from '../page/notifcation/notification-channel-form';
import NotificationChannelPage from '../page/notifcation/notification-channel-page';
import NotificationChannelUpdate from '../page/notifcation/notification-channel-update';
import NotificationHistory from '../page/notifcation/notification-history';
import NotificationPush from '../page/notifcation/notification-push';
import PromotionForm from '../page/promotion-management/promotion-form';
import PromotionPage from '../page/promotion-management/promotion-page';
import PromotionUpdate from '../page/promotion-management/promotion-update';

import DriverLocationMap from '../page/live-map/driver-location-map';
import BaseFare from '../page/pricing/base-fare';
import DistanceFare from '../page/pricing/distance-fare';
import PeakHour from '../page/pricing/peak-hour';
import PickupFare from '../page/pricing/pickup-fare';
import TimeFare from '../page/pricing/time-fare';
import TrafficCharges from '../page/pricing/traffic-charges';
import WaitingCharges from '../page/pricing/waiting-charges';
import PromotionHistory from '../page/promotion-management/promotion-history';
import RegionPage from '../page/region/region-page';
import ZonePage from '../page/region/zone-page';
import ZonePricingPage from '../page/region/zone-pricing-page';
import ZoneSetupOnMap from '../page/region/zone-set-up-on-map';
import SosAlertForm from '../page/sos-alert/sos-form';
import SosMap from '../page/sos-alert/sos-map';
import SosAlertPage from '../page/sos-alert/sos-page';
import SosAlertUpdate from '../page/sos-alert/sos-update';
import RealTimeEventTest from '../page/testing/real-time-event-test';
import TripHistory from '../page/trip-history/trip-history';
import TripHistoryDetail from '../page/trip-history/trip-history-detail';
import AdminForm from '../page/user-management/admin/admin-form';
import AdminPage from '../page/user-management/admin/admin-page';
import AdminUpdate from '../page/user-management/admin/admin-update';
import DriverForm from '../page/user-management/driver/driver-form';
import DriverPage from '../page/user-management/driver/driver-page';
import DriverUpdate from '../page/user-management/driver/driver-update';
import PassengerForm from '../page/user-management/passenger/passenger-form';
import PassengerPage from '../page/user-management/passenger/passenger-page';
import PassengerUpdate from '../page/user-management/passenger/passenger-update';
import RolePage from '../page/user-management/role/role-page';
import VehicleForm from '../page/vehicle/vehicle-form';
import VehiclePage from '../page/vehicle/vehicle-page';
import VehicleUpdate from '../page/vehicle/vehicle-update';
import CustomPath from './custom-path';

import RoleUpdate from '../page/user-management/role/role-update';
import IncentiveDefination from '../page/core-config/incentive-defination';
import Dashboard from '../page/dashboard/dashboard';
import AvailabilityCheckPage from '../page/driver-vehicle-assign/availability-check-page';
import PageNotFound from '../page/driver-vehicle-assign/page-not-found';
import GeoJsonExport from '../page/geoJsonExport';
import IncentiveHistory from '../page/incentive/incentive-history';
import DriverLiveMap from '../page/live-map/live-map';
import DriverLocationSender from '../page/map/driver-location-sender';
import PromotionUsageHistory from '../page/promotion-management/promotion-usage-history';
import TestSocket from '../page/testing/test-socket';
import TripAssignment from '../page/trip-history/trip-assigment';
import TripAssignUpdate from '../page/trip-history/trip-assign-update';
import TripDispatcherPage from '../page/trip-history/trip-dispatcher-page';
import TripComplaintList from '../page/trip-history/trip-history-report-list';
import ActiveDriverList from '../page/user-management/driver/active-driver-list';
import RoleForm from '../page/user-management/role/role-form';

function Router() {
  const location = useLocation();
  const navigate = useNavigate();
  const permissions = getPermission();
  const [userInfo, setUserInfo] = useUserInfo();
  const [userPermissions, setUserPermissions] = useState(permissions)
  const PublicRoute = () => (userInfo.accessToken ? <Navigate to="/" state={{ from: location }} /> : <Outlet />);
  useEffectOnce(() => {
    const user = getUserInfo();
    if (user) {
      setUserInfo(user);
      navigate(location.pathname === '/' ? CustomPath.dashboard : location.pathname);
    } else {
      navigate(CustomPath.login);
    }
  });

  useEffect(() => {
        const permissions = getPermission();
        setUserPermissions(permissions);
  }, [userInfo])

  return (
    <Routes>
      <Route path="/" element={<Navigate replate to={CustomPath.dashboard} />} />
      <Route element={<PublicRoute />}>
        <Route path={CustomPath.login} element={<LoginPage />} />
      </Route>
      <Route element={<App />}>
        {userPermissions?.map((item) => {
          if (item.menu === 'VEHICLE') {
            return (
              <>
                {item?.read && (
                  <>
                    <Route path={CustomPath.vehicle} element={<VehiclePage />} />
                  </>
                )}
                {item?.write && (
                  <>
                    <Route path={CustomPath.vehicle_form} element={<VehicleForm />} />
                    <Route path={CustomPath.vehicle_update_param} element={<VehicleUpdate />} />
                  </>
                )}
              </>
            );
          }

          if (item.menu === 'ADMIN') {
            return (
              <>
                {item?.read && (
                  <>
                    <Route path={CustomPath.admin} element={<AdminPage />} />
                  </>
                )}
                {item?.write && (
                  <>
                    <Route path={CustomPath.admin_form} element={<AdminForm />} />
                    <Route path={CustomPath.admin_update_param} element={<AdminUpdate />} />
                  </>
                )}
              </>
            );
          }
          if (item.menu === 'ROLE') {
            return (
              <>
                {item?.read && (
                  <>
                    <Route path={CustomPath.role} element={<RolePage />} />
                  </>
                )}
                {item?.write && (
                  <>
                    <Route path={CustomPath.role_form} element={<RoleForm />} />
                    <Route path={CustomPath.role_update_param} element={<RoleUpdate />} />
                  </>
                )}
              </>
            );
          }
          if (item.menu === 'DRIVER') {
            return (
              <>
                {item?.read && (
                  <>
                    <Route path={CustomPath.driver} element={<DriverPage />} />
                    <Route path={CustomPath.active_driver_list} element={<ActiveDriverList />} />
                  </>
                )}
                {item?.write && (
                  <>
                    <Route path={CustomPath.driver_form} element={<DriverForm />} />
                    <Route path={CustomPath.driver_update_param} element={<DriverUpdate />} />
                  </>
                )}
              </>
            );
          }
          if (item.menu === 'ACTIVE_DRIVER') {
            return (
              <>
                {item?.read && (
                  <>
                    <Route path={CustomPath.active_driver_list} element={<ActiveDriverList />} />
                  </>
                )}
              </>
            );
          }
          if (item.menu === 'PASSENGER') {
            return (
              <>
                {item?.read && (
                  <>
                    <Route path={CustomPath.passenger} element={<PassengerPage />} />
                  </>
                )}
                {item?.write && (
                  <>
                    <Route path={CustomPath.passenger_form} element={<PassengerForm />} />
                    <Route path={CustomPath.passenger_update_param} element={<PassengerUpdate />} />
                  </>
                )}
              </>
            );
          }
          if (item.menu === 'REGION') {
            return (
              <>
                {item?.read && (
                  <>
                    <Route path={CustomPath.region} element={<RegionPage />} />
                  </>
                )}
              </>
            );
          }
          if (item.menu === 'ZONE') {
            return (
              <>
                {item?.read && (
                  <>
                    <Route path={CustomPath.zone} element={<ZonePage />} />
                    <Route path={CustomPath.zone_pricing} element={<ZonePricingPage />} />
                  </>
                )}
              </>
            );
          }
          if (item.menu === 'FAQ') {
            return (
              <>
                {item?.read && (
                  <>
                    <Route path={CustomPath.faq} element={<FaqPage />} />
                  </>
                )}
                {item?.write && (
                  <>
                    <Route path={CustomPath.faq_form} element={<FaqForm />} />
                    <Route path={CustomPath.faq_update_param} element={<FaqUpdate />} />
                  </>
                )}
              </>
            );
          }
          if (item.menu === 'PROMOTION') {
            return (
              <>
                {item?.read && (
                  <>
                    <Route path={CustomPath.promotion} element={<PromotionPage />} />
                  </>
                )}
                {item?.write && (
                  <>
                    <Route path={CustomPath.promotion_form} element={<PromotionForm />} />
                    <Route path={CustomPath.promotion_update_param} element={<PromotionUpdate />} />
                  </>
                )}
              </>
            );
          }
          if (item.menu === 'PROMOTION_USAGE_HISTORY') {
            return (
              <>
                {item?.read && (
                  <>
                    <Route path={CustomPath.promotion_history} element={<PromotionHistory />} />
                    <Route path={CustomPath.promotion_usage_history} element={<PromotionUsageHistory />} />
                  </>
                )}
              </>
            );
          }
          if (item.menu === 'INCENTIVE_HISTORY') {
            return (
              <>
                {item?.read && (
                  <>
                    <Route path={CustomPath.incentive_history} element={<IncentiveHistory />} />
                  </>
                )}
              </>
            );
          }
          // if (item.menu === 'INCENTIVE_DASHBOARD') {
          //   return (
          //     <>
          //       {item?.read && (
          //         <>
          //           <Route path={CustomPath.incentive_dashboard} element={<IncentiveHistory />} />
          //         </>
          //       )}
          //     </>
          //   );
          // }
          if (item.menu === 'DRIVER_POINTS_MANAGEMENT') {
            return (
              <>
                {item?.read && (
                  <>
                    <Route path={CustomPath.driver_points} element={<DriverPoints />} />
                  </>
                )}
              </>
            );
          }
          if (item.menu === 'CHARGING_STATION') {
            return (
              <>
                {item?.read && (
                  <>
                    <Route path={CustomPath.charging_station} element={<ChargingStationPage />} />
                    <Route path={CustomPath.charging_station_map} element={<ChargingStationMap />} />
                  </>
                )}
                {item?.write && (
                  <>
                    <Route path={CustomPath.charging_station_form} element={<ChargingStationForm />} />
                    <Route path={CustomPath.charging_station_update_param} element={<ChargingStationUpdate />} />
                  </>
                )}
              </>
            );
          }
          if (item.menu === 'NOTIFICATION_CHANNEL') {
            return (
              <>
                {item?.read && (
                  <>
                    <Route path={CustomPath.notification_channel} element={<NotificationChannelPage />} />
                  </>
                )}
                {item?.write && (
                  <>
                    <Route path={CustomPath.notification_channel_form} element={<NotificationChannelForm />} />
                    <Route
                      path={CustomPath.notification_channel_update_param}
                      element={<NotificationChannelUpdate />}
                    />
                  </>
                )}
              </>
            );
          }
          if (item.menu === 'NOTIFICATION_HISTORY') {
            return (
              <>
                {item?.read && (
                  <>
                    <Route path={CustomPath.notification_history} element={<NotificationHistory />} />
                  </>
                )}
              </>
            );
          }
          if (item.menu === 'SEND_NOTIFICATION') {
            return (
              <>
                {item?.read && (
                  <>
                    <Route path={CustomPath.notification_push} element={<NotificationPush />} />
                  </>
                )}
              </>
            );
          }
          if (item.menu === 'SOS_ALERTS') {
            return (
              <>
                {item?.read && (
                  <>
                    <Route path={CustomPath.sos} element={<SosAlertPage />} />
                    <Route path={CustomPath.sos_map_param} element={<SosMap />} />
                  </>
                )}
                {item?.write && (
                  <>
                    <Route path={CustomPath.sos_form} element={<SosAlertForm />} />
                    <Route path={CustomPath.sos_update_param} element={<SosAlertUpdate />} />
                  </>
                )}
              </>
            );
          }
          if (item.menu === 'AVAILABILITY_CHECK') {
            return (
              <>
                {item?.read && (
                  <>
                    <Route path={CustomPath.driver_vehicle_availability_check} element={<AvailabilityCheckPage />} />
                  </>
                )}
              </>
            );
          }
          if (item.menu === 'TRIP_HISTORY') {
            return (
              <>
                {item?.read && (
                  <>
                    <Route path={CustomPath.trip_history} element={<TripHistory />} />
                    <Route path={CustomPath.trip_history_detail_param} element={<TripHistoryDetail />} />
                  </>
                )}
              </>
            );
          }
          if (item.menu === 'TRIP_COMPLAINT') {
            return (
              <>
                {item?.read && (
                  <>
                    <Route path={CustomPath.trip_complaint_list} element={<TripComplaintList />} />
                  </>
                )}
              </>
            );
          }
          if (item.menu === 'BASE_FARE') {
            return (
              <>
                {item?.read && (
                  <>
                    <Route path={CustomPath.base_fare} element={<BaseFare />} />
                  </>
                )}
              </>
            );
          }
          if (item.menu === 'PICKUP_FARE') {
            return (
              <>
                {item?.read && (
                  <>
                    <Route path={CustomPath.pickup_fare} element={<PickupFare />} />
                  </>
                )}
              </>
            );
          }
          if (item.menu === 'DISTANCE_FARE') {
            return (
              <>
                {item?.read && (
                  <>
                    <Route path={CustomPath.distance_fare} element={<DistanceFare />} />
                  </>
                )}
              </>
            );
          }
          if (item.menu === 'TRAFFIC_FARE') {
            return (
              <>
                {item?.read && (
                  <>
                    <Route path={CustomPath.traffic_charges} element={<TrafficCharges />} />
                  </>
                )}
              </>
            );
          }
          if (item.menu === 'TIME_FARE') {
            return (
              <>
                {item?.read && (
                  <>
                    <Route path={CustomPath.time_fare} element={<TimeFare />} />
                  </>
                )}
              </>
            );
          }
          if (item.menu === 'WAITING_FARE') {
            return (
              <>
                {item?.read && (
                  <>
                    <Route path={CustomPath.waiting_charges} element={<WaitingCharges />} />
                  </>
                )}
              </>
            );
          }
          if (item.menu === 'PEAK_HOUR_FARE') {
            return (
              <>
                {item?.read && (
                  <>
                    <Route path={CustomPath.peak_hour} element={<PeakHour />} />
                  </>
                )}
              </>
            );
          }
          if (item.menu === 'TRIP_COMPLAINTS') {
            return (
              <>
                {item?.read && (
                  <>
                    <Route path={CustomPath.trip_complaint_list} element={<TripComplaintList />} />
                  </>
                )}
              </>
            );
          }
          if (item.menu === 'WEATHER_FARE') {
            return (
              <>
                {item?.read && (
                  <>
                    <Route path={CustomPath.weather_condition} element={<WaitingCharges />} />
                  </>
                )}
              </>
            );
          }
          if (item.menu === 'INCENTIVE_RULE') {
            return (
              <>
                {item?.read && (
                  <>
                    <Route path={CustomPath.incentive_defination} element={<IncentiveDefination />} />
                  </>
                )}
              </>
            );
          }
          if (item.menu === 'TRIP_ASSIGNMENT') {
            return (
              <>
                {item?.read && (
                  <>
                    <Route path={CustomPath.trip_assignment} element={<TripAssignment />} />
                  </>
                )}
              </>
            );
          }
          if (item.menu === 'DISPATCHER') {
            return (
              <>
                {item?.read && (
                  <>
                    <Route path={CustomPath.trip_dispatcher} element={<TripDispatcherPage />} />
                    <Route path={CustomPath.trip_param} element={<TripAssignUpdate />} />
                  </>
                )}
                {item?.write && (
                  <>
                    <Route path={CustomPath.trip_param} element={<TripAssignUpdate />} />
                  </>
                )}
              </>
            );
          }
        })}{' '}
        <Route path={CustomPath.dashboard} element={<Dashboard />} />
        {/*
        <Route path={CustomPath.live_map} element={<DriverLiveMap />} />

        <Route path={CustomPath.driver_vehicle_availability_check} element={<AvailabilityCheckPage />} />
        <Route path={CustomPath.card_view} element={<CardView />} />

        <Route path={CustomPath.core_config} element={<CoreConfig />} />

        <Route path={CustomPath.core_config_form} element={<CoreConfigForm />} />
        <Route path={CustomPath.core_config_update_param} element={<CoreConfigUpdate />} />
        <Route path="/*" element={<PageNotFound />} />
        {/* <Route path={CustomPath.admin} element={<AdminPage />} />
        <Route path={CustomPath.admin_form} element={<AdminForm />} />
        <Route path={CustomPath.admin_update_param} element={<AdminUpdate />} />
        <Route path={CustomPath.dashboard} element={<Dashboard />} />
        <Route path={CustomPath.role} element={<RolePage />} />
        <Route path={CustomPath.role_form} element={<RoleForm />} />
        <Route path={CustomPath.role_update_param} element={<RoleUpdate />} />

        <Route path={CustomPath.role_and_permission_form} element={<RoleAndPermissionForm />} />
        <Route path={CustomPath.role_update_param} element={<RoleUpdate />} />
        <Route path={CustomPath.driver} element={<DriverPage />} />
        <Route path={CustomPath.driver_form} element={<DriverForm />} />
        <Route path={CustomPath.driver_update_param} element={<DriverUpdate />} />
        <Route path={CustomPath.active_driver_list} element={<ActiveDriverList />} />
        <Route path={CustomPath.passenger} element={<PassengerPage />} />
        <Route path={CustomPath.passenger_form} element={<PassengerForm />} />
        <Route path={CustomPath.passenger_update_param} element={<PassengerUpdate />} />
        <Route path={CustomPath.vehicle} element={<VehiclePage />} />
        <Route path={CustomPath.vehicle_form} element={<VehicleForm />} />
        <Route path={CustomPath.vehicle_update_param} element={<VehicleUpdate />} />

        <Route path={CustomPath.live_map} element={<DriverLiveMap />} />
        <Route path={CustomPath.region} element={<RegionPage />} />
        <Route path={CustomPath.zone} element={<ZonePage />} />
        <Route path={CustomPath.zone_pricing} element={<ZonePricingPage />} />
        <Route path={CustomPath.faq} element={<FaqPage />} />
        <Route path={CustomPath.faq_form} element={<FaqForm />} />
        <Route path={CustomPath.faq_update_param} element={<FaqUpdate />} />
        <Route path={CustomPath.promotion} element={<PromotionPage />} />
        <Route path={CustomPath.promotion_form} element={<PromotionForm />} />
        <Route path={CustomPath.promotion_update_param} element={<PromotionUpdate />} />
        <Route path={CustomPath.promotion_history} element={<PromotionHistory />} />
        <Route path={CustomPath.promotion_usage_history} element={<PromotionUsageHistory />} />
        <Route path={CustomPath.incentive_history} element={<IncentiveHistory />} />
        <Route path={CustomPath.driver_points} element={<DriverPoints />} />
        <Route path={CustomPath.charging_station} element={<ChargingStationPage />} />
        <Route path={CustomPath.charging_station_form} element={<ChargingStationForm />} />
        <Route path={CustomPath.charging_station_update_param} element={<ChargingStationUpdate />} />
        <Route path={CustomPath.charging_station_map} element={<ChargingStationMap />} />
        <Route path={CustomPath.notification_channel} element={<NotificationChannelPage />} />
        <Route path={CustomPath.notification_channel_form} element={<NotificationChannelForm />} />
        <Route path={CustomPath.notification_channel_update_param} element={<NotificationChannelUpdate />} />
        <Route path={CustomPath.sos} element={<SosAlertPage />} />
        <Route path={CustomPath.driverLocationSender} element={<DriverLocationSender />} />
        <Route path={CustomPath.sos_form} element={<SosAlertForm />} />
        <Route path={CustomPath.sos_update_param} element={<SosAlertUpdate />} />
        <Route path={CustomPath.sos_map_param} element={<SosMap />} />
        <Route path={CustomPath.notification_history} element={<NotificationHistory />} />
        <Route path={CustomPath.notification_push} element={<NotificationPush />} />
        <Route path={CustomPath.driver_vehicle_availability_check} element={<AvailabilityCheckPage />} />
        <Route path={CustomPath.trip_history} element={<TripHistory />} />
        <Route path={CustomPath.trip_history_detail_param} element={<TripHistoryDetail />} />
        <Route path={CustomPath.card_view} element={<CardView />} />
        <Route path={CustomPath.base_fare} element={<BaseFare />} />
        <Route path={CustomPath.pickup_fare} element={<PickupFare />} />
        <Route path={CustomPath.waiting_charges} element={<WaitingCharges />} />
        <Route path={CustomPath.distance_fare} element={<DistanceFare />} />
        <Route path={CustomPath.traffic_charges} element={<TrafficCharges />} />
        <Route path={CustomPath.weather_condition} element={<WeatherCondition />} />
        <Route path={CustomPath.time_fare} element={<TimeFare />} />
        <Route path={CustomPath.peak_hour} element={<PeakHour />} />
        <Route path={CustomPath.booking} element={<BookingPage />} />
        <Route path={CustomPath.trip_complaint_list} element={<TripComplaintList />} />
        <Route path={CustomPath.core_config} element={<CoreConfig />} />
        <Route path={CustomPath.incentive_defination} element={<IncentiveDefination />} />
        <Route path={CustomPath.core_config_form} element={<CoreConfigForm />} />
        <Route path={CustomPath.core_config_update_param} element={<CoreConfigUpdate />} />
        <Route path="/*" element={<PageNotFound />} />
        <Route path={CustomPath.trip_assignment} element={<TripAssignment />} />
        <Route path={CustomPath.trip_dispatcher} element={<TripDispatcherPage />} />
        <Route path={CustomPath.trip_param} element={<TripAssignUpdate />} /> */}
        <Route path="/*" element={<PageNotFound />} />
        <Route path="/v1/export-geojson" element={<GeoJsonExport />} />
      </Route>
      <Route path={CustomPath.driver_vehicle_assign} element={<DriverVehicleAssignWithCalendarV2 />} />
      <Route path={CustomPath.zone_set_up_param} element={<ZoneSetupOnMap />} />
      {userPermissions?.map((item) => {
        if (item.menu === 'ACTIVE_DRIVERS') {
          return (
            <>
              {item?.read && (
                <>
                  <Route path={CustomPath.driver_map} element={<DriverLocationMap />} />
                </>
              )}
            </>
          );
        }
      })}
      <Route path="map" element={<Testing />} />
      <Route path="map2" element={<Testing2 />} />
      <Route path="driver-map" element={<DriverLiveMap />} />
      <Route path={CustomPath.driver_location_sender} element={<DriverLocationSender />} />
      <Route path="real-time-event" element={<RealTimeEventTest />} />
      <Route path={CustomPath.login} element={<LoginPage />} />
      <Route path="driver-location" element={<TestSocket />} />
    </Routes>
  );
}
export default Router;
