import { EditOutlined, PlusOutlined, SearchOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons';
import { Button, Card, ConfigProvider, DatePicker, Input, InputNumber, Select, Table, TimePicker } from 'antd';
import React, { useEffect, useState } from 'react';
import { userPermissions } from '../core/constant';
import Theme from './theme';
import { SvgMarkerFilled } from './custom-svg-icon';
import { getPermission } from '../core/auth';
const EditableContext = React.createContext(null);

const { RangePicker } = DatePicker;

export const getRowClassName = (record, index) => {
  return index % 2 === 0 ? 'blue-row' : 'black-row';
};

export const TitleLevel3 = (props) => {
  const { label } = props;
  return <span style={{ fontSize: 27, fontWeight: '600' }}>{label}</span>;
};

export const NJVSpacer = ({ height, width }) => {
  return <div style={{ height, width, display: 'inline-block' }}></div>;
};

export const NJVAddButton = ({ handleClick, type, label }) => {
  const [userPermissions, setUserPermissions] = useState([]);

  useEffect(() => {
    const permissions = getPermission();
    setUserPermissions(permissions);
  }, []);
  const permission = userPermissions?.find((permission) => permission?.menu === type);

  // const permission = userPermissions?.find((permission) => permission?.menu === type);

  return permission?.write === true ? (
    <Button type="primary" icon={<PlusOutlined />} onClick={handleClick}>
      Create
    </Button>
  ) : null;

  // return (
  //   <Button type="primary" icon={<PlusOutlined />} onClick={handleClick}>
  //     {label || 'Create'}
  //   </Button>
  // );
};

export const NJVExportButton = ({ ...buttonProps }) => {
  const { type } = buttonProps;
  return (
    <Button
      style={{ marginRight: 10, backgroundColor: Theme.colors.green }}
      icon={<VerticalAlignBottomOutlined style={{ fontSize: '17px', fontWeight: 'bold' }} />}
      type="primary"
      {...buttonProps}
    >
      Export Excel
    </Button>
  );
};

export const NJVEditButton = ({ handleClick, type }) => {
  const [userPermissions, setUserPermissions] = useState([]);

  useEffect(() => {
    const permissions = getPermission();
    setUserPermissions(permissions);
  }, []);
  const permission = userPermissions?.find((permission) => permission?.menu === type);

  return permission?.write === true ? (
    <Button size={'middle'} type="primary" shape="circle" onClick={handleClick}>
      <EditOutlined />
    </Button>
  ) : null;

  // return (
  //   <Button size={'middle'} type="primary" shape="circle" onClick={handleClick}>
  //     <EditOutlined />
  //   </Button>
  // );
};

export const NJVSearchButton = ({ onClick }) => {
  return (
    <Button type="primary" icon={<SearchOutlined />} shape="round" onClick={onClick}>
      Search
    </Button>
  );
};

export const NJVButton = ({ children, ...buttonProps }) => {
  const { bgcolor } = buttonProps;
  return (
    <ConfigProvider
      theme={{
        token: {},
      }}
    >
      <Button {...buttonProps} style={{ width: 150, backgroundColor: bgcolor, ...buttonProps.style, height: 41 }}>
        {children}
      </Button>
    </ConfigProvider>
  );
};

export const NJVInput = ({ placeholder, ...inputProps }) => {
  const { bgcolor, ispasswordinput, istextareainput, issearchinput, style } = inputProps;

  return (
    <ConfigProvider
      theme={{
        token: {
          colorBorder: '#fff',
          colorBgContainer: `${bgcolor ? bgcolor : '#fff'}`,
          colorPrimaryHover: Theme.colors.light_gray,
          colorPrimary: 'rgb(128, 128, 128)',
        },
        components: {
          Input: {
            activeShadow: '0 0 0 0.5px rgb(128,128,128)',
            borderRadius: 9,
            borderRadiusLG: 9,
          },
          InputNumber: {
            activeShadow: '0 0 0 0.5px rgb(128,128,128)',
            borderRadius: 9,
            borderRadiusLG: 9,
          },
          Search: {
            activeShadow: '0 0 0 0.5px rgb(128,128,128)',
            borderRadius: 9,
            borderRadiusLG: 9,
          },
        },
      }}
    >
      {ispasswordinput ? (
        <Input.Password placeholder={placeholder} {...inputProps} style={{ ...style, height: 44 }} />
      ) : inputProps.isNumberInput ? (
        <InputNumber {...inputProps} style={{ ...style, height: 44 }} />
      ) : istextareainput ? (
        <Input.TextArea {...inputProps} style={{ height: 100, ...style }} />
      ) : issearchinput ? (
        <Input.Search {...inputProps} style={{ ...style, height: 44 }} />
      ) : (
        <Input placeholder={placeholder} {...inputProps} style={{ ...style, height: 44 }} />
      )}
    </ConfigProvider>
  );
};

export const NJVSelect = ({ children, ...selectProps }) => {
  const { bgcolor, style, allowClear } = selectProps;

  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgContainer: `${bgcolor ? bgcolor : '#fff'}`,
          colorBorder: '#fff',
          colorPrimary: 'rgb(128, 128, 128)',
          colorPrimaryHover: '#fff',
        },
        components: {
          Select: {
            borderRadius: '9px',
            optionPadding: '4px 12px',
            optionSelectedBg: 'rgb(128, 128, 128)',
            optionSelectedColor: '#fff',
            optionHeight: 30,
          },
        },
      }}
    >
      <Select
        {...selectProps}
        style={{ ...style, height: 44 }}
        allowClear={allowClear !== null && allowClear !== undefined ? allowClear : true}
      >
        {children}
      </Select>
    </ConfigProvider>
  );
};

export const NJVTimePicker = ({ ...datepickerProps }) => {
  const { bgcolor, style } = datepickerProps;
  return (
    <ConfigProvider
      theme={{
        token: {
          colorBorder: '#fff',
          colorPrimaryHover: '#fff',
          cellWidth: 36,
          colorPrimary: 'rgb(128, 128, 128)',
        },
        components: {
          DatePicker: {
            colorBgContainer: `${bgcolor ? bgcolor : '#fff'}`,
            activeShadow: '0 0 0 1px rgb(128, 128, 128)',
            borderRadius: '9px',
            activeBorderColor: '#fff',
          },
        },
      }}
    >
      <TimePicker {...datepickerProps} style={{ ...style, height: 44 }} />
    </ConfigProvider>
  );
};

export const NJVRangeDatePicker = ({ ...datepickerProps }) => {
  const { bgcolor, style, fullWidth } = datepickerProps;
  return (
    <ConfigProvider
      theme={{
        token: {
          colorBorder: '#fff',
          colorPrimaryHover: '#fff',
          cellWidth: 36,
          colorPrimary: 'rgb(128, 128, 128)',
        },
        components: {
          DatePicker: {
            colorBgContainer: `${bgcolor ? bgcolor : '#fff'}`,
            activeShadow: '0 0 0 1px rgb(128, 128, 128)',
            borderRadius: '9px',
            activeBorderColor: '#fff',
          },
        },
      }}
    >
      <DatePicker.RangePicker {...datepickerProps} style={{ width: fullWidth && '100%', ...style, height: 44 }} />
    </ConfigProvider>
  );
};

export const NJVDatePicker = ({ ...datepickerProps }) => {
  const { bgcolor, style } = datepickerProps;
  return (
    <ConfigProvider
      theme={{
        token: {
          colorBorder: '#fff',
          colorPrimaryHover: '#fff',
          cellWidth: 36,
          colorPrimary: 'rgb(128, 128, 128)',
        },
        components: {
          DatePicker: {
            colorBgContainer: `${bgcolor ? bgcolor : '#fff'}`,
            activeShadow: '0 0 0 1px rgb(128, 128, 128)',
            borderRadius: '9px',
            activeBorderColor: '#fff',
          },
        },
      }}
    >
      <DatePicker {...datepickerProps} style={{ ...style, height: 44 }} />
    </ConfigProvider>
  );
};

export const NJVCard = ({ children, ...cardProps }) => {
  const { cardPadding, cardBgColor, cardshadow } = cardProps;
  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgContainer: `${cardBgColor ? cardBgColor : Theme.colors.card_bg_color}`,
          boxShadowTertiary: `${cardshadow ? cardshadow : 'none'}`,
          padding: `${cardPadding ? cardPadding : '15px'}`,
          paddingLG: `${cardPadding ? cardPadding : '15px'}`,
        },
        components: {
          Card: {
            headerHeight: 56,
          },
        },
      }}
    >
      <Card {...cardProps} bordered={false}>
        {children}
      </Card>
    </ConfigProvider>
  );
};

export const NJVTable = ({ ...tableProps }) => {
  const getRowClassName = (record, index) => {
    return index % 2 === 0 ? 'table-row-gray' : 'table-row-white';
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          // colorBgContainer: Theme.colors.light_gray_2,
        },
        components: {
          Table: {
            // headerBg: 'transparent',
            borderColor: 'transparent',
          },
        },
      }}
    >
      <Table {...tableProps} rowClassName={getRowClassName} style={{ boxShadow: 'none' }} />
    </ConfigProvider>
  );
};

export const NJVReportTable = ({ ...tableProps }) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            headerBg: 'transparent',
            headerColor: '#fff',
            // rowHoverBg: 'rgba(194, 0, 47, 0.1)',
            cellPaddingBlock: '5px',
            borderColor: 'transparent',
          },
        },
      }}
    >
      <Table className="custom-table" {...tableProps} />
    </ConfigProvider>
  );
};

export const NJVRangePicker = ({ ...datepickerProps }) => {
  const { bgcolor, style } = datepickerProps;
  return (
    <ConfigProvider
      theme={{
        token: {
          colorBorder: '#fff',
          colorPrimaryHover: '#fff',
          cellWidth: 36,
          colorPrimary: 'rgb(128, 128, 128)',
        },
        components: {
          DatePicker: {
            colorBgContainer: `${bgcolor ? bgcolor : '#fff'}`,
            activeShadow: '0 0 0 1px rgb(128, 128, 128)',
            borderRadius: '9px',
            activeBorderColor: '#fff',
          },
        },
      }}
    >
      <RangePicker {...datepickerProps} style={{ ...style, height: 44 }} />
    </ConfigProvider>
  );
};

export const NJVTimeRangePicker = ({ ...datepickerProps }) => {
  const { RangePicker } = TimePicker;
  const { bgcolor, style } = datepickerProps;
  return (
    <ConfigProvider
      theme={{
        token: {
          // colorPrimaryHover: '#fff',
          cellWidth: 36,
          colorPrimary: 'rgb(128, 128, 128)',
        },
        components: {
          DatePicker: {
            // colorBgContainer: `${bgcolor ? bgcolor : '#fff'}`,
            activeShadow: '0 0 0 1px rgb(128, 128, 128)',
            borderRadius: '9px',
          },
        },
      }}
    >
      <RangePicker {...datepickerProps} style={{ ...style, width: 250, height: 35 }} />
    </ConfigProvider>
  );
};

export const NJVDeliveryStep = (props) => {
  const { from, to, status } = props;

  const iconColor = '#FF0000';

  return (
    <>
      <div style={{ display: 'flex' }}>
        <div style={{ width: 20, display: 'flex', justifyContent: 'center' }}>
          <SvgMarkerFilled width={18} height={18} color={iconColor} />
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            fontSize: 14,
            fontWeight: '600',
            marginLeft: 10,
            color: iconColor,
          }}
        >
          {from}
        </div>
      </div>
      <div style={{ display: 'flex', margin: '0px 0px 3px 0px' }}>
        <div style={{ width: 20, display: 'flex', justifyContent: 'center' }}>
          <div
            style={{
              borderLeft: `3px dotted ${iconColor}`,
              height: 15,
              margin: ' 0 auto',
            }}
          ></div>
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <div style={{ width: 20, display: 'flex', justifyContent: 'center' }}>
          <SvgMarkerFilled width={20} height={20} color={iconColor} />
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            fontSize: 14,
            fontWeight: '600',
            marginLeft: 10,
            color: iconColor,
          }}
        >
          {to}
        </div>
      </div>
    </>
  );
};

export const getTableColumns = (columns, type) => {
  const permission = userPermissions?.find((permission) => permission?.menu === type);

  return columns.filter((col) => {
    if (permission?.write === false && col.title === 'Action') {
      return false;
    }
    return true;
  });
};

export default getTableColumns;

export const ElectricityIcon = () => (
  <span
    style={{
      display: '',
      width: '16px', // width of the span
      height: '16px', // height of the span
      backgroundImage: 'url(/images/electricity.png)',
      backgroundSize: 'contain', // adjust the size of the image
      backgroundRepeat: 'no-repeat', // prevent repeating the image
      backgroundPosition: 'center center', // centers the image within the span
    }}
  />
);
