import { DeleteOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row, Select, Skeleton, Space } from 'antd';
import dayjs from 'dayjs';

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useToggle } from 'react-use';
import getTableColumns, {
  getRowClassName,
  NJVAddButton,
  NJVButton,
  NJVEditButton,
  NJVInput,
  NJVSelect,
  NJVTable,
  TitleLevel3,
} from '../../component/core-component';
import { Constant, PromotionType } from '../../core/constant';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandler } from '../../network/network-manager';
import CustomPath from '../../route/custom-path';

const PromotionPage = () => {
  const pageSize = Constant.pageSize;
  const type = 'PROMOTION';
  const [page, setPage] = useState(0);
  const [totalPagination, setTotalPagination] = useState(0);
  const [data, setData] = useState([]);
  const [isFetching, toggleDataFetching] = useToggle(false);
  const [selectedId, setSelectedId] = useState();
  const [isModelOpen, toggleModelOpen] = useToggle(false);
  const [filterData, setFilterData] = useState();

  const fetchData = (pageNumber) => {
    const requestParams = {
      size: pageSize,
      page: pageNumber ? pageNumber - 1 : page,
      promotionCode: filterData?.searchPromoCode?.trim() ? filterData?.searchPromoCode?.trim() : '',
      type: filterData?.searchPromoType?.trim() ? filterData?.searchPromoType?.trim() : '',
    };

    ApiHandler({
      url: Api.promotion_filter,
      method: HTTP_METHOD.GET,
      MediaType: MEDIA_TYPE.JSON,
      requestParams,
    })
      .then((response) => {
        setPage(pageNumber ? pageNumber - 1 : page);
        setTotalPagination(response?.totalElements);
        setData(response?.content);
      })
      .catch(() => {})
      .finally(() => toggleDataFetching(false));
  };

  const handleSearch = () => {
    fetchData();
  };

  const deleteRow = (id) => {
    ApiHandler({
      url: Api.promotion,
      method: HTTP_METHOD.DELETE,
      specificId: id,
    }).finally(() => {
      toggleModelOpen(false);
      fetchData();
    });
  };

  const onChangeFilter = (key, value) => {
    setFilterData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  useEffect(() => {
    toggleDataFetching();
    fetchData();
  }, []);

  // eslint-disable-next-line react/display-name
  // const PromotionUsageHistoryDrawer = memo(({ isOpen, closeDrawer, size, promotionId }) => {
  //   const [histories, setHistories] = useState([]);
  //   const [historyPage, setHistoryPage] = useState(0);
  //   const [historyTotalPagination, setHistoryTotalPagination] = useState(0);
  //   const [isFetchingHistories, toggleFetchingHistories] = useState(false);

  //   const pointsHistoryColumns = [
  //     {
  //       title: 'No.',
  //       key: 'index',
  //       render: (value, item, index) => <span>{historyPage * size + index + 1}</span>,
  //       width: 5,
  //     },
  //     {
  //       title: 'Promotion Code',
  //       render: (row) => <Typography.Text>{row.promotionCode}</Typography.Text>,
  //     },
  //     {
  //       title: 'Trip Number',
  //       render: (row) => <Typography.Text>{row.tripNumber}</Typography.Text>,
  //     },
  //     {
  //       title: 'Driver Name',
  //       render: (row) => <Typography.Text>{row.driverName}</Typography.Text>,
  //     },
  //     {
  //       title: 'Driver Employee ID',
  //       render: (row) => <Typography.Text>{row.driverEmployeeId}</Typography.Text>,
  //     },
  //     {
  //       title: 'Passenger Name',
  //       render: (row) => <Typography.Text>{row.passengerName}</Typography.Text>,
  //     },
  //     {
  //       title: 'Passenger Phone Number',
  //       render: (row) => <Typography.Text>{row.passengerPhoneNumber}</Typography.Text>,
  //     },
  //     {
  //       title: 'Promotion Amount',
  //       render: (row) => (
  //         <Typography.Text>{`${row.promotionAmount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography.Text>
  //       ),
  //     },
  //     {
  //       title: 'Total Trip Amount',
  //       render: (row) => (
  //         <Typography.Text>{`${row.tripBaseFees}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography.Text>
  //       ),
  //     },
  //   ];
  //   const fetchPointsHistories = (pageNumber) => {
  //     toggleFetchingHistories(true);
  //     ApiHandler({
  //       url: Api.promotion_usage_history,
  //       method: HTTP_METHOD.GET,
  //       selectedId: promotionId,
  //       requestParams: {
  //         size: pageSize,
  //         page: pageNumber ? pageNumber - 1 : historyPage,
  //       },
  //     })
  //       .then((response) => {
  //         setHistoryPage(pageNumber ? pageNumber - 1 : page);
  //         setHistoryTotalPagination(response?.totalElements);
  //         setHistories(response?.content);
  //       })
  //       .finally(() => {
  //         toggleFetchingHistories();
  //       });
  //   };
  //   useEffect(() => {
  //     fetchPointsHistories();
  //   }, []);

  //   const handleHistoryPaginationChange = (driverId, pageNumber) => {
  //     fetchPointsHistories(driverId, pageNumber);
  //   };
  //   return (
  //     <Drawer
  //       title="Promotion Usage History"
  //       width={1000}
  //       onClose={closeDrawer}
  //       open={isOpen}
  //       styles={{
  //         body: {
  //           paddingBottom: 80,
  //         },
  //       }}
  //     >
  //       <Row>
  //         <Col span={24}>
  //           {isFetchingHistories ? (
  //             <Skeleton active />
  //           ) : (
  //             <NJVTable
  //               rowKey="id"
  //               style={{ borderRadius: 10 }}
  //               columns={pointsHistoryColumns}
  //               dataSource={histories}
  //               pagination={{
  //                 position: 'top' | 'bottom',
  //                 total: historyTotalPagination,
  //                 current: historyPage + 1,
  //                 onChange: (pageNumber) => {
  //                   handleHistoryPaginationChange(pageNumber);
  //                 },
  //                 defaultPageSize: size,
  //                 showSizeChanger: false,
  //               }}
  //               rowClassName={getRowClassName}
  //             />
  //           )}
  //         </Col>
  //       </Row>
  //     </Drawer>
  //   );
  // });

  const columns = [
    {
      title: 'No.',
      key: 'index',
      render: (value, item, index) => <span>{page * pageSize + index + 1}</span>,
      width: 5,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
    },
    {
      title: 'Limit Per User',
      dataIndex: 'limitPerUser',
    },
    {
      title: 'Promotion Type',
      dataIndex: 'type',
      render: (type) => <span>{PromotionType[type]}</span>,
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
    {
      title: 'Start Date Time',
      dataIndex: 'startDateTime',
      render: (dt) => <>{dt && <span>{dayjs(dt).format('YYYY-MM-DD hh:mm A')}</span>}</>,
    },
    {
      title: 'End Date Time',
      dataIndex: 'endDateTime',
      render: (dt) => <>{dt && <span>{dayjs(dt).format('YYYY-MM-DD hh:mm A')}</span>}</>,
    },
    {
      title: 'Action',
      textAlign: 'center',
      render: (row) => (
        <Space>
          <Link to={`${CustomPath.promotion_update}/${row.id}`}>
            <NJVEditButton type={type} />
          </Link>
          <Button
            size={'middle'}
            type="primary"
            danger
            shape="circle"
            onClick={() => {
              setSelectedId(row.id);
              toggleModelOpen();
            }}
          >
            <DeleteOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  const tableColumns = getTableColumns(columns, type);

  const handlePaginationChange = (page) => {
    setPage(page - 1);
  };

  return (
    <>
      <Row gutter={[20, 20]}>
        <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
          <TitleLevel3 label={'Promotion'} />
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <Link to={CustomPath.promotion_form}>
            <NJVAddButton type={type} />
          </Link>
        </Col>
        <Col span={6}>
          <NJVInput
            bgcolor="#f1f1f1"
            allowClear
            placeholder="Search by promotion code"
            onChange={(e) => onChangeFilter('searchPromoCode', e.target.value)}
          />
        </Col>
        <Col span={6}>
          <NJVSelect
            bgcolor="#f1f1f1"
            allowClear
            size="large"
            placeholder="Search by promotion type"
            style={{ width: '100%' }}
            onChange={(e) => onChangeFilter('searchPromoType', e)}
          >
            {Object.entries(PromotionType).map(([key, value]) => (
              <Select.Option key={key} value={key}>
                {value}
              </Select.Option>
            ))}
          </NJVSelect>
        </Col>
        <Col span={3}>
          <NJVButton type="primary" style={{ width: '100%' }} onClick={handleSearch}>
            Search
          </NJVButton>
        </Col>
        <Col span={24}>
          {isFetching ? (
            <Skeleton active />
          ) : (
            <NJVTable
              rowKey="id"
              style={{ borderRadius: 10 }}
              columns={tableColumns}
              dataSource={data}
              pagination={{
                position: 'top' | 'bottom',
                total: totalPagination,
                current: page + 1,
                onChange: handlePaginationChange,
                defaultPageSize: pageSize,
                showSizeChanger: false,
              }}
              rowClassName={getRowClassName}
            />
          )}
        </Col>
      </Row>
      <Modal
        title="Delete Promotion"
        open={isModelOpen}
        onOk={() => deleteRow(selectedId)}
        okType="danger"
        onCancel={toggleModelOpen}
      >
        <p>Are you sure want to delete?</p>
      </Modal>
    </>
  );
};

export default PromotionPage;
