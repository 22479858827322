const USER_INFO_KEY = 'zeimm-user-info';
const PERMISSION_KEY = 'zeimm-user-permissions';
const FIREBASE_MESSAGING_KEY = 'zeimm-firebase-messaging-token';

export const storeUserInfo = (user) => {
  if (user) {
    localStorage.setItem(USER_INFO_KEY, JSON.stringify(user));
  }
};

export const storeUserPermission = (user) => {
  if (user) {
    localStorage.setItem(PERMISSION_KEY, JSON.stringify(user?.role?.roleMenus));
  }
};

export const storeFirebaseMessagingToken = (key) => {
  if(key) {
    localStorage.setItem(FIREBASE_MESSAGING_KEY, key)
  }
}

export const getUserInfo = () => {
  const user = localStorage.getItem(USER_INFO_KEY);
  let userInfo = null;
  if (user) {
    userInfo = JSON.parse(user || {});
  }
  return userInfo;
};

export const getPermission = () => {
  const permission = localStorage.getItem(PERMISSION_KEY);
  let json = [];
  if (permission && permission !== 'undefined') {
    json = JSON.parse(permission);
  }

  return json;
};

export const getFirebaseMessagingToken = () => {
  const key = localStorage.getItem(FIREBASE_MESSAGING_KEY)
  return key;
}

export const logout = () => {
  localStorage.removeItem(USER_INFO_KEY);
  localStorage.removeItem(PERMISSION_KEY);
  window.location.href = '/';
};
