import { Alert, Avatar, Col, Row } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { getRowClassName, NJVDatePicker, NJVSelect, NJVTable, TitleLevel3 } from '../../component/core-component';
import Theme from '../../component/theme';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { ApiHandler } from '../../network/network-manager';
import dayjs from 'dayjs';
import { UserOutlined } from '@ant-design/icons';
import {
  SvgDriverFilled,
  SvgUserCheckFilled,
  SvgUserCrossFilled,
  SvgVehicleFilled,
} from '../../component/custom-svg-icon';

const cardStyle = {
  cursor: 'pointer',
  height: '64px',
  background: Theme.colors.primary,
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: '18px',
  fontWeight: 600,
  color: 'white',
  paddingInline: 10,
};
const selectedStyle = {
  border: `2.5px solid ${Theme.colors.primary}`,
  borderRadius: 13,
  padding: 2,
  backgroundColor: 'white',
};
const unselectedStyle = {
  border: '2.5px solid white',
  borderRadius: 13,
  padding: 2,
  backgroundColor: 'white',
};
const title1 = {
  fontSize: 16,
  fontWeight: '600',
};
const title2 = {
  color: 'gray',
  fontSize: 16,
  fontWeight: '600',
};
const unAssignCardStyle = {
  backgroundColor: Theme.colors.green,
  color: 'white',
  border: 'none',
  borderRadius: 11,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingInline: 15,
  height: 50,
};

const assignCardStyle = {
  backgroundColor: Theme.colors.light_gray,
  border: 'none',
  borderRadius: 11,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingInline: 15,
  height: 50,
};
const AvailabilityCheckPage = () => {
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [assignedDrivers, setAssignedDrivers] = useState([]);
  const [unAssignedDrivers, setUnAssignedDrivers] = useState([]);
  const [assignedVehicles, setAssignedVehicles] = useState([]);
  const [unAssignedVehicles, setUnassignedVehicles] = useState([]);
  const [selectedType, setSelectedType] = useState('Driver');

  useEffect(() => {
    Promise.all([
      fetchAssignedDrivers(selectedDate.format('YYYY-MM-DD')),
      fetchUnAssignedDrivers(selectedDate.format('YYYY-MM-DD')),
    ]);
  }, []);

  const onTypeChanged = (type) => {
    setSelectedType(type);
    if (selectedDate) {
      if (type === 'Driver') {
        fetchAssignedDrivers(selectedDate.format('YYYY-MM-DD'));
        fetchUnAssignedDrivers(selectedDate.format('YYYY-MM-DD'));
      } else {
        fetchAssignedVehicles(selectedDate.format('YYYY-MM-DD'));
        fetchUnAssignedVehicles(selectedDate.format('YYYY-MM-DD'));
      }
    }
  };

  const fetchAssignedDrivers = useCallback(async (date) => {
    await ApiHandler({
      url: Api.driver_get_assigned_list_by_date,
      method: HTTP_METHOD.GET,
      requestParams: { date },
    })
      .then((response) => {
        setAssignedDrivers(response);
      })
      .catch(() => {});
  }, []);

  const fetchUnAssignedDrivers = useCallback(async (date) => {
    await ApiHandler({
      url: Api.driver_get_unassigned_list_by_date,
      method: HTTP_METHOD.GET,
      requestParams: { date },
    })
      .then((response) => {
        setUnAssignedDrivers(response);
      })
      .catch(() => {});
  }, []);

  const fetchAssignedVehicles = useCallback(async (date) => {
    await ApiHandler({
      url: Api.vehicle_get_assigned_list_by_date,
      method: HTTP_METHOD.GET,
      requestParams: { date },
    })
      .then((response) => {
        setAssignedVehicles(response);
      })
      .catch(() => {});
  }, []);

  const fetchUnAssignedVehicles = useCallback(async (date) => {
    await ApiHandler({
      url: Api.vehicle_get_unassigned_list_by_date,
      method: HTTP_METHOD.GET,
      requestParams: { date },
    })
      .then((response) => {
        setUnassignedVehicles(response);
      })
      .catch(() => {});
  }, []);

  const columns = [
    {
      title: 'No.',
      key: 'index',
      render: (value, item, index) => <span>{index + 1}</span>,
      width: 5,
    },
    {
      title: 'Driver',
      key: 'driver',
      render: (item) => (
        <div>
          {item?.profileUrl ? (
            <Avatar
              style={{ backgroundColor: Theme.colors.primary, marginRight: 10 }}
              size="large"
              src={item?.profileUrl}
            />
          ) : (
            <Avatar
              size="large"
              style={{ backgroundColor: Theme.colors.primary, marginRight: 10 }}
              icon={<UserOutlined />}
            />
          )}

          {item?.fullName}
        </div>
      ),
    },
    {
      title: 'Driver No',
      dataIndex: 'driverNo',
      width: 160,
    },
  ];

  const columnsVehicle = [
    {
      title: 'No.',
      key: 'index',
      render: (value, item, index) => <span>{index + 1}</span>,
      width: 5,
    },
    {
      title: 'Vehicle License Number',
      dataIndex: 'licenseNumber',
    },
  ];

  const onSelectDate = (date) => {
    if (date) {
      setSelectedDate(date);
      if (date && selectedType === 'Driver') {
        fetchAssignedDrivers(date.format('YYYY-MM-DD'));
        fetchUnAssignedDrivers(date.format('YYYY-MM-DD'));
      } else if (date && selectedType === 'Vehicle') {
        fetchAssignedVehicles(date.format('YYYY-MM-DD'));
        fetchUnAssignedVehicles(date.format('YYYY-MM-DD'));
      }
    } else {
      setSelectedDate(null);
      resetAll();
    }
  };

  const resetAll = () => {
    setAssignedDrivers([]);
    setUnAssignedDrivers([]);
    setUnassignedVehicles([]);
    setAssignedVehicles([]);
  };

  return (
    <Row gutter={[20, 20]}>
      <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
        <TitleLevel3 label={'Availability Check'} />
      </Col>
      <Col span={12} />
      <Col span={24}>
        <Row gutter={[8, 8]}>
          <Col span={4}>
            <div
              style={selectedType === 'Driver' ? selectedStyle : unselectedStyle}
              onClick={() => onTypeChanged('Driver')}
            >
              <div style={cardStyle}>
                <span>Driver</span>
                <SvgDriverFilled width={20} height={20} color="#FFFFFF" />
              </div>
            </div>
          </Col>
          <Col span={4}>
            <div
              style={selectedType === 'Vehicle' ? selectedStyle : unselectedStyle}
              onClick={() => onTypeChanged('Vehicle')}
            >
              <div style={cardStyle}>
                <span>Vehicle</span>
                <SvgVehicleFilled width={20} height={20} color="#FFFFFF" />
              </div>
            </div>
          </Col>
          <Col span={10} />
          <Col span={6} style={{ textAlign: 'right' }}>
            <NJVDatePicker
              value={selectedDate}
              style={{ width: '100%' }}
              bgcolor={Theme.colors.light_gray}
              onChange={onSelectDate}
            />
          </Col>
        </Row>
      </Col>

      <Col span={6}></Col>
      <Col span={12} />
      {selectedType === 'Driver' ? (
        <>
          <Col span={12}>
            <div style={unAssignCardStyle}>
              <span style={title1}>Unassigned Driver</span>
              <SvgUserCheckFilled width={22} height={22} color="white" />
            </div>
          </Col>

          <Col span={12}>
            <div style={assignCardStyle}>
              <span style={title2}>Assigned Driver</span>
              <SvgUserCrossFilled width={22} height={22} color="gray" />
            </div>
          </Col>
          <Col span={12}>
            <NJVTable
              rowKey="driverId"
              style={{ borderRadius: 10 }}
              columns={columns}
              dataSource={unAssignedDrivers}
              pagination={false}
              rowClassName={getRowClassName}
            />
          </Col>
          <Col span={12}>
            <NJVTable
              rowKey="driverId"
              style={{ borderRadius: 10 }}
              columns={columns}
              dataSource={assignedDrivers}
              pagination={false}
              rowClassName={getRowClassName}
            />
          </Col>
        </>
      ) : (
        <>
          <Col span={12}>
            <div style={unAssignCardStyle}>
              <span style={title1}>Unassigned Vehicle</span>
              <SvgUserCheckFilled width={22} height={22} color="white" />
            </div>
          </Col>

          <Col span={12}>
            <div style={assignCardStyle}>
              <span style={title2}>Assigned Vehicle</span>
              <SvgUserCrossFilled width={22} height={22} color="gray" />
            </div>
          </Col>
          <Col span={12}>
            <NJVTable
              rowKey="id"
              style={{ borderRadius: 10 }}
              columns={columnsVehicle}
              dataSource={assignedVehicles}
              pagination={false}
              rowClassName={getRowClassName}
            />
          </Col>
          <Col span={12}>
            <NJVTable
              rowKey="id"
              style={{ borderRadius: 10 }}
              columns={columnsVehicle}
              dataSource={unAssignedVehicles}
              pagination={false}
              rowClassName={getRowClassName}
            />
          </Col>
        </>
      )}
    </Row>
  );
};

export default AvailabilityCheckPage;
