import { Breadcrumb, Button, Card, Checkbox, Col, DatePicker, Form, message, Row, Select, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  NJVButton,
  NJVDatePicker,
  NJVInput,
  NJVRangeDatePicker,
  NJVSelect,
  NJVTimePicker,
} from '../../component/core-component';
import Theme from '../../component/theme';
import CustomPath from '../../route/custom-path';
import { ApiHandler } from '../../network/network-manager';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { PromotionEligibleUserType, PromotionType } from '../../core/constant';
import { NumberOnlyForOptionalValidator, NumberOnlyValidator } from '../../core/validator';
import dayjs from 'dayjs';

function PromotionForm() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const defaultStartTime = dayjs('00:00', 'HH:mm');
  const defaultEndTime = dayjs('23:59', 'HH:mm');

  const [loading, setLoading] = useState(false);

  const randomPromotionCode = (length = 6) => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    form.setFieldValue('promotionCode', `ZEIMM${result}`);
  };

  const createPromotion = (formData) => {
    const [startDate, endDate] = formData.dateRange;
    if (formData.type === PromotionType.Percentage && formData.amount > 100) {
      message.error('The promotion percentage cannot exceed 100%');
      return;
    }
    setLoading(true);
    ApiHandler({
      url: Api.promotion,
      method: HTTP_METHOD.POST,
      requestData: {
        ...formData,
        startDateTime: startDate.format('YYYY-MM-DD HH:mm'),
        endDateTime: endDate.format('YYYY-MM-DD HH:mm'),
      },
    })
      .then(() => {
        backToHome();
      })
      .catch(() => setLoading(false));
  };

  const backToHome = () => navigate(CustomPath.promotion);

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Breadcrumb
            items={[
              {
                title: 'Home',
              },
              {
                title: <Link to={CustomPath.promotion}>Promotion</Link>,
              },
            ]}
          />
        </Col>
        <Col span={24}>
          <Card
            style={{
              backgroundColor: Theme.colors.card_bg_color,
              boxShadow: 'none',
            }}
            bordered={false}
            title={'Promotion Form'}
            extra={
              <Button type="primary" onClick={backToHome}>
                Back
              </Button>
            }
          >
            <Form
              form={form}
              layout="vertical"
              initialValues={{ startTime: defaultStartTime, endTime: defaultEndTime }}
              onFinish={createPromotion}
            >
              <Row gutter={[16, 16]}>
                <Col span={7}>
                  <Form.Item
                    name="promotionCode"
                    label="Promotion Code"
                    rules={[
                      { required: true, message: '*(requried)' },
                      { min: 4, message: 'Promotion Code must be at least 5 characters' },
                    ]}
                  >
                    <NJVInput placeholder={'Enter Promotion Code'} />
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item name="isGeneratedCode" label="Generate Promotion Code?">
                    <NJVButton type="primary" onClick={() => randomPromotionCode()}>
                      Generate
                    </NJVButton>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="type" label={'Promotion Type'} rules={[{ required: true, message: '*(requried)' }]}>
                    <NJVSelect placeholder="Select Promotion Type">
                      {Object.entries(PromotionType).map(([key, value]) => (
                        <Select.Option key={key} value={key}>
                          {value}
                        </Select.Option>
                      ))}
                    </NJVSelect>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="dateRange"
                    label={'Eligible Date Time'}
                    rules={[{ required: true, message: '*(requried)' }]}
                  >
                    <NJVRangeDatePicker
                      size="large"
                      style={{ width: '100%' }}
                      showTime={{
                        format: 'HH:mm',
                      }}
                      format="YYYY-MM-DD HH:mm"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="amount"
                    label={'Amount'}
                    rules={[{ required: true, message: '*(requried)' }, { validator: NumberOnlyValidator }]}
                  >
                    <NJVInput placeholder={'Enter Amount'} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="minimumAmount"
                    label={'Minimum Amount'}
                    rules={[{ validator: NumberOnlyForOptionalValidator }]}
                  >
                    <NJVInput placeholder={'Enter Minimum Amount'} type="number" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="limitPerUser"
                    label={'Limit Per User'}
                    rules={[{ validator: NumberOnlyForOptionalValidator }]}
                  >
                    <NJVInput placeholder={'Enter Limit Per User'} type="number" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="userLimit"
                    label={'User Limit'}
                    rules={[{ validator: NumberOnlyForOptionalValidator }]}
                  >
                    <NJVInput placeholder={'Enter User Limit'} type="number" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="description" label={'Description'} required>
                    <NJVInput
                      istextareainput={true}
                      autosize={{ minRows: 3, maxRows: 5 }}
                      placeholder={'Enter Description'}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="eligibleUserType"
                    label={'Eligible User Type'}
                    rules={[{ required: true, message: '*(requried)' }]}
                  >
                    <NJVSelect placeholder={'Select Eligible User Type'}>
                      {Object.entries(PromotionEligibleUserType).map(([key, value]) => (
                        <Select.Option key={key} value={key}>
                          {value}
                        </Select.Option>
                      ))}
                    </NJVSelect>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    shouldUpdate={(prevValues, currentValues) => {
                      return prevValues.eligibleUserType !== currentValues.eligibleUserType;
                    }}
                  >
                    {({ getFieldValue }) =>
                      getFieldValue('eligibleUserType') === 'LOYALTY' ? (
                        <Form.Item
                          name="numberOfTrip"
                          label={'Number Of Trip'}
                          rules={[{ required: true, message: '*(requried)' }]}
                        >
                          <NJVInput placeholder={'Enter Number Of Trip'} type="number" />
                        </Form.Item>
                      ) : null
                    }
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item>
                    <NJVButton type="primary" htmlType="submit" loading={loading}>
                      Create
                    </NJVButton>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default PromotionForm;
