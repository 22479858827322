import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row, Skeleton, Space, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import getTableColumns, {
  getRowClassName,
  NJVAddButton,
  NJVButton,
  NJVEditButton,
  NJVInput,
  NJVTable,
  TitleLevel3,
} from '../../component/core-component';
import Theme from '../../component/theme';
import { Constant } from '../../core/constant';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandler } from '../../network/network-manager';
import CustomPath from '../../route/custom-path';

const NotificationChannelPage = () => {
  const pageSize = Constant.pageSize;
  const type = 'NOTIFICATION_CHANNEL';
  const [page, setPage] = useState(0);
  const [totalPagination, setTotalPagination] = useState(0);
  const [userTypes, setUserTypes] = useState([]);
  const [filterData, setFilterData] = useState({});
  const [data, setData] = useState([]);
  const [dataFetching, setDataFetching] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    fetchUserTypes();
    fetchData();
  }, []);

  const fetchUserTypes = async () => {
    await ApiHandler({
      url: Api.enum_filter,
      method: HTTP_METHOD.GET,
      mediaType: MEDIA_TYPE.JSON,
      requestParams: { enumType: 'USER_TYPE' },
    })
      .then((response) => {
        setUserTypes(response);
      })
      .catch(() => { });
  };

  const fetchData = async (pageNumber) => {
    setDataFetching(true);
    const requestParams = {
      size: pageSize,
      page: pageNumber ? pageNumber - 1 : page,
      channel: filterData?.searchChannel?.trim() ? filterData?.searchChannel?.trim() : '',
      title: filterData?.searchTitle?.trim() ? filterData?.searchTitle?.trim() : '',
    };
    await ApiHandler({
      url: Api.notification_channel_filter,
      method: HTTP_METHOD.GET,
      MediaType: MEDIA_TYPE.JSON,
      requestParams,
    })
      .then((response) => {
        const temp = response?.content.map((notification) => {
          if (notification?.userTypes) {
            notification['userTypeList'] = notification?.userTypes.split(',');
          }
          return notification;
        });

        setData(temp);
        setPage(pageNumber ? pageNumber - 1 : page);
        setTotalPagination(response?.totalElements);
      })
      .catch(() => { })
      .finally(() => {
        setDataFetching(false);
      });
  };

  const onChangeFilter = (key, value) => {
    setFilterData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleSearch = () => {
    setPage(0);
    fetchData(1);
  };

  const handlePaginationChange = (pageNumber) => {
    fetchData(pageNumber);
  };

  const deleteNotification = async () => {
    await ApiHandler({ url: Api.notification_channel, method: HTTP_METHOD.DELETE, specificId: deleteId })
      .then(() => {
        fetchData();
        setDeleteId(null);
        setOpenModal(false);
      })
      .catch(() => { });
  };
  const columns = [
    {
      title: 'No.',
      key: 'index',
      render: (value, item, index) => <span>{page * pageSize + index + 1}</span>,
      width: 5,
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Content',
      dataIndex: 'content',
      key: 'content',
    },
    {
      title: 'Notification Type',
      dataIndex: 'notificationType',
      key: 'notificationType',
    },
    {
      title: 'User Type',
      key: 'userType',
      width: 120,
      render: ({ userType, sendSpecificUser }) => (
        <>
          {sendSpecificUser ? (
            <Tag color={Theme.colors.default}>Specific User</Tag>
          ) : (
            <Tag color="green">{userType}</Tag>
          )}
        </>
      ),
    },
    {
      title: 'Status',
      key: 'status',
      width: 40,
      render: (_, { status }) => (
        <>{status === 'Active' ? <Tag color="#87d068">{status}</Tag> : <Tag color="#cd201f">{status}</Tag>}</>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: '',
      textAlign: 'center',
      width: 60,
      render: (notification_channel) => (
        <Space>
          <Link to={`${CustomPath.notification_channel_update}/${notification_channel.id}`}>
            <NJVEditButton type={type} />
          </Link>
          {
            notification_channel?.notificationType === 'TRIP_EVENT' || notification_channel?.notificationType === 'SOS' ?
              <></>
              :
              <Button
                size={'middle'}
                type="primary"
                shape="circle"
                onClick={() => {
                  setDeleteId(notification_channel.id);
                  setOpenModal(true);
                }}
              >
                <DeleteOutlined />
              </Button>
          }
        </Space>
      ),
    },
  ];

  const tableColumns = getTableColumns(columns, type);
  return (
    <>
      <Modal
        title="Delete"
        open={openModal}
        onOk={() => deleteNotification()}
        onCancel={() => setOpenModal(false)}
        okText="Confirm"
        cancelText="Cancel"
      >
        <p>Are you sure to delete?</p>
      </Modal>
      <Row gutter={[16, 16]}>
        <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
          <TitleLevel3 label={'Notification Channel'} />
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <Link to={CustomPath.notification_channel_form}>
            <NJVAddButton type={type} />
          </Link>
        </Col>
        <Col span={6}>
          <NJVInput
            bgcolor={Theme.colors.light_gray}
            onChange={(e) => onChangeFilter('searchChannel', e.target.value)}
            placeholder="Search by Channel"
            name="channel"
          />
        </Col>
        <Col span={6}>
          <NJVInput
            bgcolor={Theme.colors.light_gray}
            onChange={(e) => onChangeFilter('searchTitle', e.target.value)}
            placeholder="Search by Title"
            name="title"
          />
        </Col>
        <Col span={6} className="search">
          <NJVButton onClick={handleSearch} type="primary" style={{ width: '100%' }}>
            Search
          </NJVButton>
        </Col>
        <Col span={24}>
          {dataFetching ? (
            <Skeleton active />
          ) : (
            <NJVTable
              style={{ borderRadius: 10 }}
              pagination={{
                position: 'top' | 'bottom',
                total: totalPagination,
                current: page + 1,
                onChange: handlePaginationChange,
                defaultPageSize: pageSize,
                showSizeChanger: false,
              }}
              columns={tableColumns}
              dataSource={data}
              rowClassName={getRowClassName}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default NotificationChannelPage;
