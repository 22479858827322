import MapboxDraw from '@mapbox/mapbox-gl-draw';
import * as turf from '@turf/turf';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import React from 'react';
import { compose } from 'redux';
import withRouter from '../../component/with-router';

import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import { ApiHandler } from '../../network/network-manager';
import Api from '../../network/api';
import { MEDIA_TYPE } from '../../network/mediaType';
import { HTTP_METHOD } from '../../network/httpMethod';
import { Button } from 'antd';
import { colorConstant, mapBoxAccessToken } from '../../core/constant';
import { Link } from 'react-router-dom';
import CustomPath from '../../route/custom-path';

const convertNestedLngLatListToObjList = (data) => {
  const tempLnglats = [];
  data?.map((lnglats) => {
    lnglats?.map((lnglat) => tempLnglats.push({ lng: lnglat[0], lat: lnglat[1] }));
  });
  return tempLnglats;
};

const convertObjListToNestedLngLatList = (data) => {
  const tempLnglats = [];
  data?.map((lnglat) => {
    if (lnglat?.lng && lnglat?.lat) {
      tempLnglats.push([lnglat.lng, lnglat.lat]);
    }
  });
  return tempLnglats;
};

class ZoneSetupOnMap extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      isDataFetching: true,
      roundedArea: null,
      selectedPolyData: [],
      otherZonePolyData: [],
    };
    this.mapContainer = React.createRef();
  }
  componentDidMount() {
    mapboxgl.accessToken = mapBoxAccessToken;
    this.map = new mapboxgl.Map({
      container: this.mapContainer,
      style: 'mapbox://styles/mapbox/streets-v11',
      zoom: 14,
    });
    this.draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true,
      },
      defaultMode: 'simple_select',
    });

    this.map.on('load', () => {
      this.map.setCenter([96.08616299784325, 16.82649501109621]);
    });

    this.map.addControl(this.draw);
    this.map.on('draw.create', (e) => this.updateArea(e, this.draw));
    this.map.on('draw.delete', (e) => this.updateArea(e, this.draw));
    this.map.on('draw.update', (e) => this.updateArea(e, this.draw));

    this.fetchData();
  }

  updateArea = (e, draw) => {
    const data = draw.getAll();
    if (data.features.length > 0) {
      console.log(data?.features[0]?.geometry?.coordinates);

      this.setState({
        selectedPolyData: data?.features[0]?.geometry?.coordinates,
      });
    }
    if (e?.type === 'draw.delete') {
      this.setState({
        selectedPolyData: [],
      });
    }
  };

  checkConflictPolygonPoint = (coordinates) => {
    const { otherZonePolyData } = this.state;
    coordinates?.map((coor) => {
      coor?.map((lnglat) => {
        otherZonePolyData?.map((polyData) => {
          const point = turf.point([lnglat[0], lnglat[1]]);
          const polygon = turf.polygon([polyData]);
          if (turf.booleanPointInPolygon(point, polygon)) {
            return true;
          }
        });
      });
    });
  };

  fetchData = async () => {
    await ApiHandler({
      url: Api.zone,
      mediaType: MEDIA_TYPE.JSON,
      method: HTTP_METHOD.GET,
      specificId: this.props.params.id,
    })
      .then((res) => {
        const polygon = convertObjListToNestedLngLatList(res?.locations);
        this.drawEditablePolygon(polygon);
        this.setState({ data: res });
        this.fetchOtherZoneWithSameRegion(res?.region?.id);
      })
      .catch(() => {});
  };

  fetchOtherZoneWithSameRegion = async (regionId) => {
    await ApiHandler({
      url: Api.zone_find_by_region,
      mediaType: MEDIA_TYPE.JSON,
      method: HTTP_METHOD.GET,
      requestParams: { regionId },
    })
      .then((res) => {
        const otherZonePolyData = [];
        res?.map((zone, index) => {
          if (zone?.id != this.props.params.id) {
            const polygon = convertObjListToNestedLngLatList(zone?.locations);
            if (polygon && polygon.length !== 0) {
              otherZonePolyData.push(polygon);
              this.drawUnEditablePolygon(polygon, zone, colorConstant[index]);
            }
          }
        });
        this.setState({ otherZonePolyData });
      })
      .catch(() => {});
  };

  updateZone = async () => {
    const { selectedPolyData } = this.state;
    const tempLnglats = convertNestedLngLatListToObjList(selectedPolyData);
    await ApiHandler({
      url: Api.zone,
      mediaType: MEDIA_TYPE.JSON,
      method: HTTP_METHOD.PUT,
      requestData: { id: this.props.params.id, locations: tempLnglats },
    })
      .then((res) => {
        this.setState({ data: res });
      })
      .catch(() => {});
    // .finally(() => backToHome());
  };

  drawEditablePolygon = (locations) => {
    if (locations && locations.length !== 0) {
      const polygon = turf.polygon([locations]);
      this.draw.add(polygon);
    }
  };

  drawUnEditablePolygon = (locations, zone, color) => {
    const polygon = turf.polygon([locations]);
    const center = turf.center(polygon);
    this.map.addSource(`${zone.id}`, {
      type: 'geojson',
      data: polygon,
    });

    this.map.addLayer({
      id: `${zone.id}`,
      type: 'fill',
      source: `${zone.id}`,
      layout: {},
      paint: {
        'fill-color': color,
        'fill-opacity': 0.8,
      },
    });
    this.map.addSource(`${zone.id}-text`, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: center.geometry,
            properties: {
              text: zone?.name,
            },
          },
        ],
      },
    });
    this.map.addLayer({
      id: `${zone.id}-text`,
      type: 'symbol',
      source: `${zone.id}-text`,
      layout: {
        'text-field': ['get', 'text'],
        'text-size': 13,
        'text-offset': [0, 0],
        'text-anchor': 'bottom',
        'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
      },
      paint: {
        'text-color': '#fff',
      },
    });
  };

  render() {
    const { data } = this.state;
    return (
      <>
        <div ref={(el) => (this.mapContainer = el)} style={{ width: '100%', height: '100vh', borderRadius: 5 }}></div>
        <div style={{ position: 'fixed', top: 10, left: 10 }}>
          <Link to={CustomPath.zone}>
            <Button type="primary">Back</Button>
          </Link>
          <span style={{ marginLeft: 10 }}>
            {data?.name} ({data?.region?.regionName})
          </span>
        </div>
        <Button type="primary" style={{ position: 'fixed', top: 10, right: 45 }} onClick={() => this.updateZone()}>
          Update Zone
        </Button>
      </>
    );
  }
}

export default compose(withRouter)(ZoneSetupOnMap);
