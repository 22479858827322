import { Breadcrumb, Button, Card, Col, Form, message, Row, Select, Upload } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { NJVButton, NJVDatePicker, NJVInput, NJVSelect } from '../../component/core-component';
import Theme from '../../component/theme';
import { InsuranceCompany, VehicleType } from '../../core/constant';
import { ValidateFromDateToDate } from '../../core/validator';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandler } from '../../network/network-manager';
import CustomPath from '../../route/custom-path';
import { UploadOutlined } from '@ant-design/icons';

function VehicleForm() {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const formRef = useRef();
  const [loading, setLoading] = useState(false);
  const [regions, setRegions] = useState([]);
  const [documentFileList, setDocumentFileList] = useState([]);
  const [uploadedDocumentFile, setUploadedDocumentFile] = useState();

  const fetchRegions = () => {
    ApiHandler({
      url: Api.regions_all,
      method: HTTP_METHOD.GET,
      MediaType: MEDIA_TYPE.JSON,
    })
      .then((drivers) => {
        setRegions(drivers);
      })
      .catch(() => {});
  };

  const createVehicle = async (formData) => {
    setLoading(true);
    const requestFormData = new FormData();
    requestFormData.append('manufacturer', formData.manufacturer);
    requestFormData.append('model', formData.model);
    requestFormData.append('yearOfProduction', formData.yearOfProduction);
    requestFormData.append('licenseNumber', formData.licenseNumber);
    requestFormData.append('insuranceInformation', formData.insuranceInformation);
    requestFormData.append('insuranceExpiryDate', formData.insuranceExpiryDate.format('YYYY-MM-DD'));
    requestFormData.append('licenseFromDate', formData.fromDate.format('YYYY-MM-DD'));
    requestFormData.append('licenseToDate', formData.toDate.format('YYYY-MM-DD'));
    requestFormData.append('color', formData.color);
    requestFormData.append('batteryCapacity', formData.batteryCapacity);
    requestFormData.append('gpsTrackingDeviceNumber', formData.gpsTrackingDeviceNumber);
    requestFormData.append('licensePlateNumberRegionCode', formData.licensePlateNumberRegionCode);
    requestFormData.append('insuranceCompany', formData.insuranceCompany);
    requestFormData.append('regionId', formData.regionId);

    formData.vehicleType && requestFormData.append('vehicleType', formData.vehicleType);
    formData.seatingCapacity && requestFormData.append('seatingCapacity', formData.seatingCapacity);
    formData.chassisNumber && requestFormData.append('chassisNumber', formData.chassisNumber);
    formData.chargingConnectorType && requestFormData.append('chargingConnectorType', formData.chargingConnectorType);
    uploadedDocumentFile && requestFormData.append('documentFile', uploadedDocumentFile);

    await ApiHandler({
      url: Api.vehicle,
      method: HTTP_METHOD.POST,
      requestData: requestFormData,
    })
      .then(() => {
        backToHome();
      })
      .catch(() => setLoading(false));
  };

  const backToHome = () => navigate(CustomPath.vehicle);

  const disableFutureDates = (current) => {
    return current && current < dayjs().endOf('day');
  };

  const onChangeLiscenseNumber = (e) => {
    const licenseNumber = e.target.value;
    const numberAndCapitalLetterRex = /^[A-Z0-9-]*$/;
    let finalLicenseeNumber = '';
    if (licenseNumber.includes('-') && licenseNumber.length > 2) {
      finalLicenseeNumber =
        licenseNumber.substring(0, 1) +
        licenseNumber.substring(1, 2) +
        licenseNumber.substring(2, 3) +
        checkLicenseNumberOnly(licenseNumber.substring(3, 4)) +
        checkLicenseNumberOnly(licenseNumber.substring(4, 5)) +
        checkLicenseNumberOnly(licenseNumber.substring(5, 6)) +
        checkLicenseNumberOnly(licenseNumber.substring(6, 7));
    } else if (licenseNumber.length > 2) {
      finalLicenseeNumber =
        licenseNumber.substring(0, 1) +
        licenseNumber.substring(1, 2) +
        '-' +
        checkLicenseNumberOnly(licenseNumber.substring(2, 3));
    } else if (licenseNumber.length > 1) {
      if (numberAndCapitalLetterRex.test(licenseNumber.substring(1, 2))) {
        finalLicenseeNumber = licenseNumber.substring(0, 1) + licenseNumber.substring(1, 2);
      } else {
        message.error('Please enter capital letter or number');
        finalLicenseeNumber = licenseNumber.substring(0, 1);
      }
    } else {
      if (numberAndCapitalLetterRex.test(licenseNumber.substring(0, 1))) {
        finalLicenseeNumber = licenseNumber.substring(0, 1);
      } else {
        message.error('Please enter capital letter or number');
        finalLicenseeNumber = null;
      }
    }
    formRef.current.setFieldsValue({ licenseNumber: finalLicenseeNumber });
  };

  const checkLicenseNumberOnly = (str) => {
    const numberOnlyRegex = /^[0-9]+$/;
    if (str && str !== undefined && numberOnlyRegex.test(str)) {
      return str;
    } else if (str !== '') {
      message.error('Please enter number only');
    }
    return '';
  };

  useEffect(() => {
    fetchRegions();
  }, []);

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Breadcrumb
            items={[
              {
                title: 'Home',
              },
              {
                title: <Link to={CustomPath.vehicle}>Vehicle</Link>,
              },
            ]}
          />
        </Col>
        <Col span={24}>
          <Card
            style={{
              backgroundColor: Theme.colors.card_bg_color,
              boxShadow: 'none',
            }}
            bordered={false}
            title={'Vehicle Form'}
            extra={
              <Button type="primary" onClick={backToHome}>
                Back
              </Button>
            }
          >
            <Form ref={formRef} layout="vertical" initialValues={{ active: true }} onFinish={createVehicle}>
              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <Form.Item
                    name="manufacturer"
                    label={'Manufacturer'}
                    rules={[{ required: true, message: '*(requried)' }]}
                  >
                    <NJVInput placeholder={'Enter manufacturer'} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="model" label={'Model'} rules={[{ required: true, message: '*(requried)' }]}>
                    <NJVInput placeholder={'Enter Model'} />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    name="yearOfProduction"
                    label={'Year Of Production'}
                    rules={[{ required: true, message: '*(requried)' }]}
                  >
                    <NJVInput placeholder={'Enter year of production'} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="licenseNumber"
                    label={'License Plate Number'}
                    rules={[{ required: true, message: '*(requried)' }]}
                  >
                    <NJVInput
                      style={{ letterSpacing: 5 }}
                      onChange={onChangeLiscenseNumber}
                      // placeholder="Enter License Plate Number"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="licensePlateNumberRegionCode"
                    label={'License Registered Location'}
                    rules={[{ required: true, message: '*(requried)' }]}
                  >
                    <NJVInput placeholder={'Enter License Registered Location'} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="fromDate"
                    label={'License From Date'}
                    rules={[{ required: true, message: '*(requried)' }, { validator: ValidateFromDateToDate(form) }]}
                  >
                    <NJVDatePicker size="large" style={{ width: '100%' }} placeholder={'Select license from date'} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="toDate"
                    label={'License To Date'}
                    rules={[{ required: true, message: '*(requried)' }, { validator: ValidateFromDateToDate(form) }]}
                  >
                    <NJVDatePicker size="large" style={{ width: '100%' }} placeholder={'Select license to date'} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="insuranceCompany"
                    label={'Insurance Company'}
                    rules={[{ required: true, message: '*(requried)' }]}
                  >
                    <NJVSelect size="large" placeholder="Select Insurance Company">
                      {Object.entries(InsuranceCompany).map(([key, value]) => (
                        <Select.Option key={key} value={key}>
                          {value}
                        </Select.Option>
                      ))}
                    </NJVSelect>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="insuranceInformation"
                    label={'Insurance Information'}
                    rules={[{ required: true, message: '*(requried)' }]}
                  >
                    <NJVInput placeholder={'Enter insurance information'} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="insuranceExpiryDate"
                    label={'Insurance Expiry Date'}
                    rules={[{ required: true, message: '*(requried)' }]}
                  >
                    <NJVDatePicker
                      size="large"
                      style={{ width: '100%' }}
                      disabledDate={disableFutureDates}
                      placeholder={'Select insurance expiry date'}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="batteryCapacity" label={'Battery Capacity'}>
                    <NJVInput placeholder={'Enter battery capacity'} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="chargingConnectorType" label={'Charging Connector Type'}>
                    <NJVInput placeholder={'Enter charging connector type'} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="color" label={'Color'} rules={[{ required: true, message: '*(requried)' }]}>
                    <NJVInput placeholder={'Enter color'} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="seatingCapacity" label={'Seating Capacity'}>
                    <NJVInput placeholder={'Enter seating capacity'} type="number" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="regionId" label={'Assigned Hub'}>
                    <NJVSelect placeholder="Select Assigned Hub">
                      {regions.map((region) => (
                        <Select.Option key={region.id} value={region.id}>
                          {region.regionName}
                        </Select.Option>
                      ))}
                    </NJVSelect>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="chassisNumber"
                    label={'Chassis Number'}
                    rules={[{ required: true, message: '*(requried)' }]}
                  >
                    <NJVInput placeholder={'Enter Chassis Number'} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="gpsTrackingDeviceNumber"
                    label={'GPS Tracking Device Number'}
                    rules={[{ required: true, message: '*(requried)' }]}
                  >
                    <NJVInput placeholder={'Enter GPS Tracking Device Number'} />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    name="vehicleType"
                    label={'Vehicle Type'}
                    rules={[{ required: true, message: '*(requried)' }]}
                  >
                    <NJVSelect size="large" placeholder="Select Vehicle Type" style={{ width: '100%' }}>
                      {Object.entries(VehicleType).map(([key, value]) => (
                        <Select.Option key={key} value={key}>
                          {value}
                        </Select.Option>
                      ))}
                    </NJVSelect>
                  </Form.Item>
                </Col>
                <Form.Item label={'Document'}>
                  <Upload
                    accept=".pdf, .doc, .docx, .xls, .xlsx"
                    beforeUpload={(file) => {
                      setUploadedDocumentFile(file);
                      return false;
                    }}
                    onRemove={() => {
                      setUploadedDocumentFile(undefined);
                    }}
                    onChange={({ fileList: newFileList }) => setDocumentFileList(newFileList)}
                  >
                    {documentFileList.length === 0 && (
                      <Button size="large" icon={<UploadOutlined />}>
                        Select File
                      </Button>
                    )}
                  </Upload>
                </Form.Item>
                <Col span={24}>
                  <Form.Item>
                    <NJVButton type="primary" htmlType="submit" loading={loading}>
                      Create
                    </NJVButton>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default VehicleForm;
