import { CaretDownOutlined, CaretUpOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, message, Modal, Row, Tag } from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { NJVButton, NJVDatePicker, NJVInput, TitleLevel3 } from '../../component/core-component';
import CoreTableComponent from '../../component/core-table-component';
import Theme from '../../component/theme';
import { NumberOnlyValidator } from '../../core/validator';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandler } from '../../network/network-manager';

const cashTransactionStatusMap = {
  PENDING: 'Pending',
  COLLECTED_BY_ADMIN: 'Collected',
};

function IncentiveHistory() {
  const [filterData, setFilterData] = useState({ incentiveDate: dayjs().format('YYYY-MM-DD') });
  const [inputData, setInputData] = useState('');
  const [selectedData, setSelectedData] = useState(null);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openConfirmSureModel, setOpenConfirmSureModel] = useState(false);
  const [refreshKey, setRefreshKey] = useState(false);
  const [collectData, setCollectData] = useState();
  const [activeSort, setActiveSort] = useState({ columnKey: null, direction: null });
  const type = 'Incentive History';

  const handleSortIconClick = (columnKey, direction) => {
    if (activeSort.columnKey === columnKey && activeSort.direction === direction) {
      handleSearch();
      setActiveSort({ columnKey: null, direction: null });
    } else {
      setActiveSort({ columnKey, direction });
      if (direction === 'ascend') {
        handleSearch('totalIncomeAmount,asc');
      } else if (direction === 'descend') {
        handleSearch('totalIncomeAmount,desc');
      } else {
        handleSearch();
      }
    }
  };

  const handleSearch = (params) => {
    const requestParams = {
      driverName: inputData?.searchDriverName?.trim() ? inputData?.searchDriverName?.trim() : '',
      phoneNumber: inputData?.searchPhoneNumber?.trim() ? inputData?.searchPhoneNumber?.trim() : '',
      incentiveDate: inputData?.searchDate ? dayjs(inputData.searchDate).format('YYYY-MM-DD') : '',
      sort: params ? params : '',
    };
    setFilterData(requestParams);
  };

  const onChangeFilter = (key, value) => {
    setInputData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const manageCollectData = (data) => {
    setCollectData(data);
    setOpenConfirmSureModel(true);
  };

  const onCollectCash = async () => {
    let existingCash = 0;
    selectedData?.driverDailyIncomeTransactions?.forEach((item) => {
      if (item.paymentMethod === 'Cash') {
        existingCash = item.totalIncomeAmount;
        return;
      }
    });
    message.destroy();
    if (existingCash === 0) {
      message.error('Undefined existing cash');
      return;
    }
    if (collectData.amount != existingCash) {
      message.error('Existing income amount does not match');
      return;
    }

    await ApiHandler({
      url: Api.daily_income_collect,
      method: HTTP_METHOD.PUT,
      mediaType: MEDIA_TYPE.JSON,
      requestData: { totalIncomeAmount: collectData.amount },
      specificId: selectedData?.id,
    })
      .then(() => {
        setRefreshKey(true);
        setOpenConfirmModal(false);
        setOpenConfirmSureModel(false);
      })
      .catch(() => {})
      .finally(() => {});
  };

  const columns = [
    {
      title: 'Driver Name',
      dataIndex: 'driver',
      render: (driver) => (
        <>
          <div>{driver.fullName}</div>
          <div>{driver.phoneNumber}</div>
        </>
      ),
    },
    {
      title: (
        <div style={{ display: 'flex' }}>
          Total Income Amount
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'end', marginLeft: 'auto' }}>
            <CaretUpOutlined
              onClick={() => handleSortIconClick('income', 'ascend')}
              style={{
                color: activeSort.columnKey === 'income' && activeSort.direction === 'ascend' ? '#1890ff' : 'gray',
                cursor: 'pointer',
              }}
            />
            <CaretDownOutlined
              onClick={() => handleSortIconClick('income', 'descend')}
              style={{
                color: activeSort.columnKey === 'income' && activeSort.direction === 'descend' ? '#1890ff' : 'gray',
                cursor: 'pointer',
              }}
            />
          </div>
        </div>
      ),
      dataIndex: 'totalIncomeAmount',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Target Progress',
      dataIndex: 'incentiveAmount',
      render: (incentiveAmount) => (
        <>
          {incentiveAmount === 0 ? <Tag color={Theme.colors.orange}>Processing</Tag> : <span>{incentiveAmount} %</span>}
        </>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'createdDate',
      render: (v) => <span>{dayjs(v).format('MMM DD, YYYY')}</span>,
    },
    {
      title: 'Cash',
      dataIndex: 'driverDailyIncomeTransactions',
      render: (v) =>
        v
          .map((item) => (item.paymentMethod === 'Cash' ? item.totalIncomeAmount : null))
          .filter(Boolean)
          .join(', '),
    },
    {
      title: 'Kpay',
      dataIndex: 'driverDailyIncomeTransactions',
      render: (v) =>
        v
          .map((item) => (item.paymentMethod === 'KPay' ? item.totalIncomeAmount : null))
          .filter(Boolean)
          .join(', '),
    },
    {
      title: 'Status',
      render: (item) => (
        <>
          {item.cashTransactionStatus === 'DRIVER_DELIVERED' ? (
            <Button
              type="primary"
              icon={<CheckCircleOutlined />}
              onClick={() => {
                setOpenConfirmModal(true);
                setSelectedData(item);
              }}
            >
              Collect
            </Button>
          ) : (
            <Tag color={item.itemcashTransactionStatus === 'PENDING' ? Theme.colors.default : Theme.colors.green}>
              {cashTransactionStatusMap[item.cashTransactionStatus]}
            </Tag>
          )}
        </>
      ),
      width: 100,
    },
  ];

  return (
    <>
      <Modal
        style={{ top: 50 }}
        title={'Collect Cash'}
        onOk={() => onCollectCash}
        onCancel={() => setOpenConfirmSureModel(false)}
        width={400}
        footer={[
          <Button key="No" onClick={() => setOpenConfirmSureModel(false)} type="primary" danger>
            No
          </Button>,
          <Button key="Yes" type="primary" onClick={onCollectCash}>
            Yes
          </Button>,
        ]}
        open={openConfirmSureModel}
      >
        <p>Are you sure to collect?</p>
      </Modal>
      <Modal
        title={'Confirmation'}
        onCancel={() => {
          setOpenConfirmModal(false);
          setSelectedData(null);
        }}
        open={openConfirmModal}
        footer={null}
      >
        <Form layout="vertical" onFinish={manageCollectData}>
          <Form.Item
            name="amount"
            label="Total cash collected from driver"
            rules={[{ required: true, message: '*(requried)' }, { validator: NumberOnlyValidator }]}
          >
            <NJVInput placeholder="Please enter collected cash amount" bgcolor={Theme.colors.light_gray} />
          </Form.Item>
          <Form.Item>
            <NJVButton type="primary" htmlType="submit">
              Collect
            </NJVButton>
          </Form.Item>
        </Form>
      </Modal>
      <Row style={{ alignItems: 'center' }} gutter={[20, 20]}>
        <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
          <TitleLevel3 label={'Incentive History'} />
        </Col>
        <Col span={6}>
          <NJVInput
            bgcolor="#f1f1f1"
            placeholder="Search By DriverName"
            onChange={(e) => onChangeFilter('searchDriverName', e.target.value)}
          />
        </Col>
        <Col span={6}>
          <NJVInput
            bgcolor="#f1f1f1"
            placeholder="Search By PhoneNumber"
            onChange={(e) => onChangeFilter('searchPhoneNumber', e.target.value)}
          />
        </Col>
        <Col span={6}>
          <NJVDatePicker
            onChange={(e) => onChangeFilter('searchDate', e)}
            bgcolor="#f1f1f1"
            style={{ width: '100%' }}
            // defaultValue={filterData?.incentiveDate}
            defaultValue={dayjs(filterData?.incentiveDate, 'YYYY-MM-DD')}
          />
        </Col>
        <Col span={6}>
          <NJVButton type="primary" style={{ width: '100%' }} onClick={() => handleSearch()}>
            Search
          </NJVButton>
        </Col>
        <Col span={24}>
          <CoreTableComponent
            componentColumns={columns}
            url={Api.daily_income_history}
            filterData={filterData}
            refreshKey={refreshKey}
            type={type}
          />
        </Col>
      </Row>
    </>
  );
}
export default IncentiveHistory;
