/* eslint-disable react-hooks/exhaustive-deps */
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Form, message, Modal, Row, Skeleton, Space, Tag } from 'antd';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useToggle } from 'react-use';
import getTableColumns, {
  ElectricityIcon,
  getRowClassName,
  NJVAddButton,
  NJVButton,
  NJVEditButton,
  NJVInput,
  NJVTable,
  NJVTimeRangePicker,
  TitleLevel3,
} from '../../component/core-component';
import { Constant } from '../../core/constant';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandler } from '../../network/network-manager';
import CustomPath from '../../route/custom-path';

const ChargingStationPage = () => {
  const type = 'CHARGING_STATION';
  const pageSize = Constant.pageSize;
  const [page, setPage] = useState(0);
  const [totalPagination, setTotalPagination] = useState(0);
  const [data, setData] = useState([]);
  const [isFetching, toggleDataFetching] = useToggle(false);
  const [selectedId, setSelectedId] = useState();
  const [isModelOpen, toggleModelOpen] = useToggle(false);
  const [filterData, setFilterData] = useState({});
  const [rowPickers, setRowPickers] = useState({});
  const [isOpen, setIsOpen] = useState(false)
  const [timeData, setTimeData] = useState([])
  const [index, setIndex] = useState(null)
  const [isCancelAction, setIsCancelAction] = useState(false);
  const cancelActionRef = useRef(isCancelAction);


  const fetchData = (pageNumber, pickerId, rowIndex) => {
    const requestParams = {
      size: pageSize,
      page: pageNumber ? pageNumber - 1 : page,
      name: filterData?.searchFullName?.trim() ? filterData?.searchFullName?.trim() : '',
      address: filterData?.searchAddress?.trim() ? filterData?.searchAddress?.trim() : '',
    };
    ApiHandler({
      url: Api.charging_station_all,
      method: HTTP_METHOD.GET,
      MediaType: MEDIA_TYPE.JSON,
      requestParams,
    })
      .then((response) => {
        const content = response?.content;
        setData(content);
        setPage(pageNumber ? pageNumber - 1 : page);
        setTotalPagination(response?.totalElements);
        if (cancelActionRef) {
          const matchingData = response?.content?.find((data) => data.id === index);
          setTimeData(matchingData ? matchingData : null);
        }
      })
      .catch((error) => {
        message.error(error);
      })
      .finally(() => {
        toggleDataFetching(false)

        renderPickers(index, timeData)
      })
  };


  const handleSearch = () => {
    fetchData();
  };

  useEffect(() => {
    toggleDataFetching();
    fetchData();
  }, []);

  const deleteStation = (id) => {
    ApiHandler({
      url: Api.charging_station,
      method: HTTP_METHOD.DELETE,
      specificId: id,
    }).finally(() => {
      toggleModelOpen(false);
      fetchData();
    });
  };

  const onChangeFilter = (key, value) => {
    setFilterData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };


  const handlePickerChange = async (id, pickerId, pickerIndex, record, value, day) => {
    setRowPickers((prev) => {
      const updatedPickers = { ...prev };

      if (!updatedPickers[id]) {
        console.log("upkr:", updatedPickers[id])
        updatedPickers[id] = { day1: [], day2: [] };
      }

      const [fromTime, toTime] = value || [null, null];
      const timeData = {
        index: pickerIndex + 1,
        fromTime: fromTime ? dayjs(fromTime).format("hh:mm A") : null,
        toTime: toTime ? dayjs(toTime).format("hh:mm A") : null,
      };

      if (day === "day1") {
        updatedPickers[id].day1 = [];
        console.log("Resetting day1");
        console.log("day1")
        if (pickerIndex < (record[0]?.length || 0)) {
          record[0][pickerIndex] = timeData;
        } else {
          const rowPickerIndex = pickerIndex - (record[0]?.length || 0);
          updatedPickers[id].day1[rowPickerIndex] = timeData;
        }

      } else if (day === "day2") {
        updatedPickers[id].day2 = [];
        console.log("Resetting day2");
        if (pickerIndex < (record[1]?.length || 0)) {
          record[1][pickerIndex] = timeData;
        } else {
          const rowPickerIndex = pickerIndex - (record[1]?.length || 0);
          updatedPickers[id].day2[rowPickerIndex] = timeData;
        }
      }

      return updatedPickers;
    });
    const updatedPickers = rowPickers[id] || { day1: [], day2: [] };

    const filterEmptyPickers = (pickers) => {
      return pickers.filter((item) => item.fromTime && item.toTime);
    };

    const payload = {
      electricityGroupDTOS: [
        [
          ...((record[0] || []).map((item, index) => ({
            index: index + 1,
            fromTime: item.fromTime,
            toTime: item.toTime,
          }))),
          ...(filterEmptyPickers(updatedPickers.day1 || [])),
        ],
        [
          ...((record[1] || []).map((item, index) => ({
            index: index + 1,
            fromTime: item.fromTime,
            toTime: item.toTime,
          }))),
          ...(filterEmptyPickers(updatedPickers.day2 || [])),
        ],
      ],
    };

    try {
      await ApiHandler({
        url: Api.charging_station_update_electricity,
        method: HTTP_METHOD.PUT,
        requestData: payload,
        specificId: id,
      }).finally(() => fetchData());
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const addDay1Picker = (recordIndex, record) => {
    setRowPickers((prev) => {
      const updatedPickers = { ...prev };

      if (!updatedPickers[recordIndex]) {
        updatedPickers[recordIndex] = { day1: [], day2: [] };
      }

      const newIndex =
        (record[0]?.length || 0) + (updatedPickers[recordIndex].day1.length || 0) + 1;

      updatedPickers[recordIndex].day1.push({
        index: newIndex,
        fromTime: null,
        toTime: null,
      });

      return updatedPickers;
    });
  };

  const addDay2Picker = (recordIndex, record) => {
    setRowPickers((prev) => {
      const updatedPickers = { ...prev };

      if (!updatedPickers[recordIndex]) {
        updatedPickers[recordIndex] = { day1: [], day2: [] };
      }

      const newIndex =
        (record[1]?.length || 0) + (updatedPickers[recordIndex].day2.length || 0) + 1;

      updatedPickers[recordIndex].day2.push({
        index: newIndex,
        fromTime: null,
        toTime: null,
      });

      return updatedPickers;
    });
  };


  const edit = (index, data, record) => {
    setIsOpen(true);
    setTimeData(record);
    setIndex(index);
    setRowPickers((prev) => {
      const updatedPickers = { ...prev };
      if (!updatedPickers[index]) {
        updatedPickers[index] = { day1: [], day2: [] };
      }

      if ((!updatedPickers[index].day1 || updatedPickers[index].day1.length === 0) && (!data[0] || data[0].length === 0)) {
        updatedPickers[index].day1.push({
          index: record[0]?.length + 1,
          fromTime: null,
          toTime: null,
        });
      }

      if ((!updatedPickers[index].day2 || updatedPickers[index].day2.length === 0) && (!data[1] || data[1].length === 0)) {
        updatedPickers[index].day2.push({
          index: record[1]?.length + 1,
          fromTime: null,
          toTime: null,
        });
      }

      return updatedPickers;
    });
  };

  const cancelPicker = async (recordIndex, pickerIndex, record, dayKey) => {
    const recordIndexKey = dayKey === "day1" ? 0 : 1;

    setIsCancelAction(true)

    const combinedPickers = [
      ...(record[recordIndexKey] || []),
      ...(rowPickers[recordIndex]?.[dayKey]?.filter(
        (picker) =>
          !record[recordIndexKey]?.some(
            (existing) =>
              existing.fromTime === picker.fromTime && existing.toTime === picker.toTime
          )
      ) || []),
    ];


    const updatedCombinedPickers = combinedPickers.filter(
      (_, index) => index !== pickerIndex
    );

    const updatedRecord = record.map((dayData, idx) =>
      idx === recordIndexKey ? updatedCombinedPickers.slice(0, record[recordIndexKey]?.length) : dayData
    );

    const updatedRowPickers = {
      ...rowPickers,
      [recordIndex]: {
        ...rowPickers[recordIndex],
        [dayKey]: updatedCombinedPickers.slice(record[recordIndexKey]?.length),
      },
    };

    setRowPickers(updatedRowPickers);

    const removedPicker = combinedPickers[pickerIndex];
    if (removedPicker?.fromTime && removedPicker?.toTime) {
      try {
        const requestData = {
          electricityGroupDTOS: updatedRecord.map((group) =>
            group.map((item) => ({
              index: item.index,
              fromTime: item.fromTime,
              toTime: item.toTime,
            }))
          ),
        };

        await ApiHandler({
          url: Api.charging_station_update_electricity,
          method: HTTP_METHOD.PUT,
          requestData,
          specificId: recordIndex,
        });
        setIsCancelAction(true)
      } catch (error) {
        console.error("Error during API call:", error);
      } finally {
        fetchData()
      }
    }
  }

  const renderPickers = useCallback(
    (id, record) => {
      const rowPickerData = rowPickers[id] || { day1: [], day2: [] };
      const removeDuplicates = (pickers) => {
        const seen = new Map();
        return pickers.filter((picker) => {
          if (seen.has(picker.index)) {
            return false;
          }
          seen.set(picker.index, true);
          return true;
        });
      };

      const allPickersDay1 = removeDuplicates([
        ...(record?.electricityGroupDTOS?.[0] || []),
        ...rowPickerData?.day1 || []
      ]);
      const allPickersDay2 = removeDuplicates([
        ...(record?.electricityGroupDTOS?.[1] || []),
        ...rowPickerData?.day2 || []
      ]);


      return (
        <div>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
            <div style={{ display: 'block' }}>
              <div style={{ fontWeight: [500], color: 'gray', marginBottom:'10px' }}><span style={{fontSize:'18px'}}>{record?.name}</span> ({record?.address})</div>
              <div style={{ fontSize: "25px", fontWeight: "bold" }}>Day 1</div>
            </div>
          </div>
          {allPickersDay1.map((picker, pickerIndex) => {
            const value =
              picker.fromTime && picker.toTime
                ? [dayjs(picker.fromTime, "hh:mm A"), dayjs(picker.toTime, "hh:mm A")]
                : null;

            return (
              <Space key={picker.id || pickerIndex} style={{ display: "flex", marginBottom: 8 }}>
                <Form.Item style={{ margin: 0 }}>
                  <NJVTimeRangePicker
                    value={value}
                    use12Hours
                    format="hh:mm A"
                    style={{ marginRight: 2 }}
                    onChange={(selectedValue) =>
                      handlePickerChange(id, picker.id, pickerIndex, record?.electricityGroupDTOS, selectedValue, "day1")
                    }
                    allowClear={false}
                  />
                </Form.Item>
                {pickerIndex === allPickersDay1.length - 1 && (
                  <>
                    {pickerIndex !== 2 && (
                      <Button
                        type="primary"
                        shape="circle"
                        icon={<PlusOutlined />}
                        onClick={() => addDay1Picker(id, record?.electricityGroupDTOS)}
                      />
                    )}
                    {pickerIndex !== 0 && (
                      <Button
                        type="primary"
                        shape="circle"
                        icon={<DeleteOutlined />}
                        onClick={() => cancelPicker(id, pickerIndex, record?.electricityGroupDTOS, "day1")}
                        danger
                      />
                    )}
                  </>
                )}
              </Space>
            );
          })}

          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
            <div style={{ fontSize: "25px", fontWeight: "bold" }}>Day 2</div>
          </div>
          {allPickersDay2.map((picker, pickerIndex) => {
            const value =
              picker.fromTime && picker.toTime
                ? [dayjs(picker.fromTime, "hh:mm A"), dayjs(picker.toTime, "hh:mm A")]
                : null;

            return (
              <Space key={picker.id || pickerIndex} style={{ display: "flex", marginBottom: 8 }}>
                <Form.Item style={{ margin: 0 }}>
                  <NJVTimeRangePicker
                    value={value}
                    use12Hours
                    format="hh:mm A"
                    style={{ marginRight: 2 }}
                    onChange={(selectedValue) =>
                      handlePickerChange(id, picker.id, pickerIndex, record?.electricityGroupDTOS, selectedValue, "day2")
                    }
                    allowClear={false}
                  />
                </Form.Item>
                {pickerIndex === allPickersDay2.length - 1 && (
                  <>
                    {pickerIndex !== 2 && (
                      <Button
                        type="primary"
                        shape="circle"
                        icon={<PlusOutlined />}
                        onClick={() => addDay2Picker(id, record?.electricityGroupDTOS)}
                      />
                    )}
                    {pickerIndex !== 0 && (
                      <Button
                        type="primary"
                        shape="circle"
                        icon={<DeleteOutlined />}
                        onClick={() => cancelPicker(id, pickerIndex, record?.electricityGroupDTOS, "day2")}
                        danger
                      />
                    )}
                  </>
                )}
              </Space>
            );
          })}
        </div>
      );
    },
    [rowPickers, handlePickerChange, addDay1Picker, addDay2Picker, cancelPicker]
  );

  const columns = [
    {
      title: 'No.',
      key: 'index',
      render: (value, item, index) => <span>{page * pageSize + index + 1}</span>,
      width: 5,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: '18%'
    },
    {
      title: 'Address',
      dataIndex: 'address',
      width: '23%'
    },
    {
      title: 'Electricity Availability',
      dataIndex: 'electricityGroupDTOS',
      children: [
        {
          title: 'Day1',
          key: 'day1',
          dataIndex: 'electricityGroupDTOS',
          render: (data = [], record, index) => {
            return data[0]?.length > 0 ? (
              <Space direction="vertical">
                {data[0].map((slot, index) => (
                  <Tag key={index} style={{ fontSize: '14px', padding: '4px 8px', borderRadius: '6px' }} size='large'>
                    {slot.fromTime} ~ {slot.toTime}
                  </Tag>
                ))}
              </Space>
            ) : (
              <Button shape="circle" onClick={() => edit(record.id, data, record)} style={{ marginRight: 10 }} type="primary">
                <ElectricityIcon />
              </Button>
            )
          },
        },
        {
          title: 'Day2',
          key: 'day2',
          dataIndex: 'electricityGroupDTOS',
          render: (data = [], record, index) => {
            return data[1]?.length > 0 ? (
              <Space direction="vertical">
                {data[1].map((slot, index) => (
                  <div style={{ display: 'flex', alignItems: 'center', gap: '1px' }}>
                    <Tag
                      style={{
                        fontSize: '14px',
                        padding: '4px 8px',
                        borderRadius: '6px',
                        width: '150px'
                      }}
                      size="large"
                    >
                      {slot.fromTime} ~ {slot.toTime}
                    </Tag>
                    {index + 1 === data[1].length &&
                      <Button
                        shape="circle"
                        onClick={() => edit(record.id, data, record)}
                        style={{ marginRight: '10px', height: '28px', minWidth: '28px' }}
                        type="primary"
                      >
                        <EditOutlined />
                      </Button>}
                  </div>
                ))}
              </Space>
            ) : (
              <Button shape="circle" onClick={() => edit(record.id, data, record)} style={{ marginRight: 10 }} type="primary">
                <ElectricityIcon />
              </Button>
            )
          },
        },
      ],
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '15%',
      render: (_, { status }) => (
        <>
          {status === 'Active' ? (
            <Tag color="#87d068">{Constant.userStatus[status]}</Tag>
          ) : (
            <Tag color="#cd201f">{Constant.userStatus[status]}</Tag>
          )}
        </>
      ),
    },
    {
      title: 'Action',
      textAlign: 'center',
      width: '12%',
      render: (row) => (
        <Space>
          <Link to={`${CustomPath.charging_station_update}/${row.id}`}>
            <NJVEditButton type={type} />
          </Link>
          <Button
            size={'middle'}
            type="primary"
            danger
            shape="circle"
            onClick={() => {
              setSelectedId(row.id);
              toggleModelOpen();
            }}
          >
            <DeleteOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  const handlePaginationChange = (pageNumber) => {
    fetchData(pageNumber);
  };

  const tableColumns = getTableColumns(columns, type);
  return (
    <>
      <Drawer open={isOpen} closable={true} onClose={() => setIsOpen(false)}>
        {renderPickers(index, timeData)}
      </Drawer>
      <Row style={{ alignItems: 'center' }} gutter={[20, 20]}>
        <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
          <TitleLevel3 label={'Charging Station'} />
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <Link to={CustomPath.charging_station_form}>
            <NJVAddButton type={type} />
          </Link>
        </Col>
        <Col span={6}>
          <NJVInput
            bgcolor="#f1f1f1"
            placeholder="Search by name"
            onChange={(e) => onChangeFilter('searchFullName', e.target.value)}
          />
        </Col>
        <Col span={6}>
          <NJVInput
            bgcolor="#f1f1f1"
            placeholder="Search by address"
            onChange={(e) => onChangeFilter('searchAddress', e.target.value)}
          />
        </Col>
        <Col span={3}>
          <NJVButton type="primary" style={{ width: '100%' }} onClick={handleSearch}>
            Search
          </NJVButton>
        </Col>
        <Col span={24}>
          {isFetching ? (
            <Skeleton active />
          ) : (
            <NJVTable
              rowKey="id"
              style={{ borderRadius: 10 }}
              columns={tableColumns}
              dataSource={data}
              pagination={{
                position: 'top' | 'bottom',
                total: totalPagination,
                current: page + 1,
                onChange: handlePaginationChange,
                defaultPageSize: pageSize,
                showSizeChanger: false,
              }}
              rowClassName={getRowClassName}
            />
          )}
        </Col>
      </Row>
      <Modal
        title="Delete Charging Station"
        open={isModelOpen}
        onOk={() => deleteStation(selectedId)}
        okType="danger"
        onCancel={toggleModelOpen}
      >
        <p>Are you sure want to delete?</p>
      </Modal>
    </>
  );
};

export default ChargingStationPage;