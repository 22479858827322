import { Button, Card, Col, Input, List, message, Modal, Row, Segmented, Select, Skeleton, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { NJVCard, NJVInput, NJVSelect, TitleLevel3 } from '../../component/core-component';
import { Constant } from '../../core/constant';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandler } from '../../network/network-manager';
import Theme from '../../component/theme';
import '../../css/push-notification.css';
import { SvgPaperPlaneFilled } from '../../component/custom-svg-icon';

const NotificationPush = () => {
  const pageSize = Constant.pageSize;
  const [page, setPage] = useState(0);
  const [totalPagination, setTotalPagination] = useState(0);
  const [userTypes, setUserTypes] = useState([]);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState({});
  const [dataFetching, setDataFetching] = useState(false);
  const [initialState, setInitialState] = useState(false);
  const [messageTitle, setMessageTitle] = useState(null);
  const [segmentValue, setSegmentValue] = useState('Initial Value');
  const [pushId, setPushId] = useState(null);
  const [sending, setSending] = useState(false);
  const [messageContent, setMessageContent] = useState(null);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  useEffect(() => {
    fetchUserTypes();
    fetchData();
  }, []);

  const fetchUserTypes = async () => {
    await ApiHandler({
      url: Api.enum_filter,
      method: HTTP_METHOD.GET,
      mediaType: MEDIA_TYPE.JSON,
      requestParams: { enumType: 'USER_TYPE' },
    })
      .then((response) => {
        const temp = response.map(res => ({ label: res.value, value: res.key }))
        setUserTypes(temp);
      })
      .catch((error) => { });
  };

  const fetchData = async (pageNumber) => {
    setDataFetching(true);
    const requestParams = {
      size: pageSize,
      page: pageNumber ? pageNumber - 1 : page,
      channel: filterData?.searchChannel?.trim() ? filterData?.searchChannel?.trim() : '',
      title: filterData?.searchTitle?.trim() ? filterData?.searchTitle?.trim() : '',
      userType: filterData?.searchUserType?.trim() ? filterData?.searchUserType?.trim() : '',
    };
    await ApiHandler({
      url: Api.notification_channel_filter,
      method: HTTP_METHOD.GET,
      mediaType: MEDIA_TYPE.JSON,
      requestParams,
    })
      .then((response) => {
        const temp = response?.content.map((notification) => {
          if (notification?.userTypes) {
            notification['userTypeList'] = notification?.userTypes.split(',');
          }
          return notification;
        });

        setData(temp);
        setTotalPagination(response?.totalElements);
        setPage(pageNumber ? pageNumber : page);
      })
      .catch(() => { })
      .finally(() => {
        setDataFetching(false);
      });
  };

  const pushNotification = () => {
    setSending(true);

    ApiHandler({
      url: Api.notification_push_by_topic,
      method: HTTP_METHOD.POST,
      specificId: pushId,
      disableShowMessage: true,
    })
      .then(() => {
        message.success('Successfully Send!');
      })
      .catch(() => { })
      .finally(() => {
        setOpenConfirmationModal(false);
        setInitialState(true);
        setSegmentValue('Initial State');
        setSending(false);
        setMessageTitle(null);
        setMessageContent(null);
        setPushId(null);
      });
  };

  const onChangeFilter = (key, value) => {
    setFilterData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const onChangeUserType = (key, value) => {
    setFilterData((prev) => ({
      ...prev,
      searchUserType: value,
    }));
  };

  return (
    <>
      <Modal
        title="Please confirm your notification content"
        open={openConfirmationModal}
        onCancel={() => {
          setOpenConfirmationModal(false);
          setPushId(null);
          setMessageTitle(null);
          setMessageContent(null);
          setInitialState(true);
          setSegmentValue('Initial State');
        }}
        okText="Confirm"
        cancelText="Cancel"
        footer={null}
      >
        <Modal>
          <Segmented
            value={segmentValue}
            size="large"
            block
            onChange={(value) => {
              setInitialState(!initialState);
              setSegmentValue(value);
            }}
            options={['Initial State', 'Expanded View']}
          />
          <br />
          <div className="mobile-container">
            <div className="mobile-status-bar" />
            <div className="noti-container">
              <div
                style={{
                  fontWeight: '500',
                  fontSize: 14,
                }}
              >
                {messageTitle}
              </div>
              <div className={`${initialState ? 'content-style-initial' : 'content-style-expand'} `}>
                {messageContent}
              </div>
            </div>
          </div>
          <div style={{ textAlign: 'center', marginTop: 15 }}>
            {sending ? (
              <Button size="large" loading={sending} type="primary">
                Sending
              </Button>
            ) : (
              <Button
                onClick={() => pushNotification()}
                size="large"
                type="primary"
                icon={<SvgPaperPlaneFilled width={15} height={15} color={'#fff'} />}
              >
                Send Now
              </Button>
            )}
          </div>
        </Modal>
      </Modal>

      <Row gutter={[16, 32]}>
        <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
          <TitleLevel3 label={'Send Notification'} />
        </Col>

        <Col span={6}>
          <NJVInput
            bgcolor={Theme.colors.light_gray}
            onChange={(e) => onChangeFilter('searchTitle', e.target.value)}
            placeholder="Search by Title"
            name="title"
          />
        </Col>
        <Col span={6}>
          <NJVSelect
            size="large"
            bgcolor={Theme.colors.light_gray}
            placeholder="Search by user type"
            onChange={(value) => onChangeUserType('searchUserType', value)}
            style={{
              width: '100%',
            }}
            options={userTypes}
          />
        </Col>
        <Col span={6} className="search">
          <Button onClick={() => fetchData()} type="primary" style={{ borderRadius: 10, height: 40, width: '100%' }}>
            Search
          </Button>
        </Col>
        <Col span={24}>
          {dataFetching ? (
            <Skeleton active />
          ) : (
            <List
              pagination={{
                position: 'bottom',
                align: 'end',
              }}
              dataSource={data}
              renderItem={(item) => (
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <NJVCard
                      style={{ marginBottom: 20 }}
                      className="card-shadow"
                      cardBgColor={'#fff'}
                      bordered={true}
                      title={item.title}
                      extra={(item?.notificationType === 'TRIP_EVENT' || item?.notificationType === 'SOS') ? <></> :
                        <Button
                          type="primary"
                          onClick={() => {
                            setOpenConfirmationModal(true);
                            setPushId(item.id);
                            setMessageTitle(item.title);
                            setMessageContent(item.content);
                          }}
                          icon={<SvgPaperPlaneFilled width={15} height={15} color={'#fff'} />}
                        >
                          Send Notification
                        </Button>
                      }
                    >
                      <div className="title-label">Content</div>
                      <div className="label">{item.content}</div>
                      <div className="title-label">Receipent</div>
                      <Tag size="large" color="#108ee9">
                        {item.userType}
                      </Tag>
                    </NJVCard>
                  </Col>
                </Row>
              )}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default NotificationPush;
