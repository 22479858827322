import { EyeOutlined } from '@ant-design/icons';
import { Button, Col, Row, Select, Skeleton, Space } from 'antd';
import { default as React, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getRowClassName, NJVButton, NJVSelect, NJVTable, TitleLevel3 } from '../../component/core-component';
import Theme from '../../component/theme';
import { ComplaintsType, Constant } from '../../core/constant';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandler } from '../../network/network-manager';
import CustomPath from '../../route/custom-path';

const apiData = [
  { lat: 40.44356, lng: -79.91746, dbh: 0 },
  { lat: 40.44961, lng: -79.94606, dbh: 12 },
  { lat: 40.46283, lng: -79.96474, dbh: 6 },
];

function TripComplaintList() {
  const pageSize = Constant.pageSize;
  const [page, setPage] = useState(0);
  const [totalPagination, setTotalPagination] = useState(0);
  const [dataFetching, setDataFetching] = useState(false);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState();

  const handleSearch = () => {
    fetchData();
  };

  const fetchData = (pageNumber) => {
    const requestParams = {
      size: pageSize,
      page: pageNumber ? pageNumber - 1 : page,
      tripComplaintType: filterData?.tripComplaintType?.trim() ? filterData?.tripComplaintType?.trim() : '',
    };

    Object.keys(requestParams).forEach((key) => {
      if (requestParams[key] === null || requestParams[key] === undefined || requestParams[key] === '') {
        delete requestParams[key];
      }
    });

    ApiHandler({
      url: Api.trip_complaints,
      method: HTTP_METHOD.GET,
      MediaType: MEDIA_TYPE.JSON,
      requestParams,
    })
      .then((response) => {
        setPage(pageNumber ? pageNumber - 1 : page);
        setTotalPagination(response?.totalElements);
        setData(response?.content);
      })
      .catch(() => {})
      .finally(() => setDataFetching(false));
  };

  useEffect(() => {
    setDataFetching(true);
    fetchData();
  }, []);

  const columns = [
    {
      title: 'No.',
      key: 'index',
      render: (value, item, index) => <span>{page * pageSize + index + 1}</span>,
      width: 5,
    },
    {
      title: 'Trip Number',
      dataIndex: 'tripNumber',
      key: 'tripNumber',
    },
    {
      title: 'Complaint Category',
      dataIndex: 'tripComplaintType',
      key: 'tripComplaintType',
    },
    {
      title: 'Complaint Reason',
      key: 'complaint',
      dataIndex: 'complaint',
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: '',
      textAlign: 'center',
      width: 60,
      render: (trip) => (
        <Space>
          <Link to={`${CustomPath.trip_history_detail}/${trip.tripId}`}>
            <Button type="primary" shape="circle" icon={<EyeOutlined />} />
          </Link>
        </Space>
      ),
    },
  ];

  const handlePaginationChange = (pageNumber) => {
    setFilterData((prev) => ({
      ...prev,
      pageNumber,
    }));
  };

  const onChangeSelect = (key, value) => {
    setFilterData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <>
      <Row style={{ alignItems: 'center' }} gutter={[20, 20]}>
        <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
          <TitleLevel3 label={'Trip Complaint List'} />
        </Col>
        <Col span={6}>
          <NJVSelect
            bgcolor={Theme.colors.light_gray}
            size="large"
            placeholder="Search by ComplaintsType"
            onChange={(value) => onChangeSelect('tripComplaintType', value)}
            style={{ width: '100%' }}
          >
            {Object.entries(ComplaintsType).map(([key, value]) => (
              <Select.Option key={key} value={key}>
                {value}
              </Select.Option>
            ))}
          </NJVSelect>
        </Col>
        <Col span={6}>
          <NJVButton type="primary" style={{ width: '100%' }} onClick={handleSearch}>
            Search
          </NJVButton>
        </Col>
        <Col span={24}>
          {dataFetching ? (
            <Skeleton active />
          ) : (
            <NJVTable
              rowKey={'id'}
              style={{ borderRadius: 10 }}
              pagination={{
                position: 'top' | 'bottom',
                total: totalPagination,
                current: page + 1,
                onChange: handlePaginationChange,
                defaultPageSize: pageSize,
                showSizeChanger: false,
              }}
              columns={columns}
              dataSource={data}
              rowClassName={getRowClassName}
            />
          )}
        </Col>
      </Row>
    </>
  );
}
export default TripComplaintList;
