import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ApiHandler, ApiHandlerDriverLocation } from '../../network/network-manager';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { PhoneNumberValidator } from '../../core/validator';
import {
  AutoComplete,
  Avatar,
  Breadcrumb,
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Spin,
  Tag,
  Timeline,
  Typography,
} from 'antd';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions.css';
import { NJVButton, NJVDatePicker, NJVInput, NJVSelect } from '../../component/core-component';
import Theme from '../../component/theme';
import { mapBoxAccessToken } from '../../core/constant';
import { useDebounce, useEffectOnce, useGeolocation } from 'react-use';
import { over } from 'stompjs';
import SockJS from 'sockjs-client';
import { AwsPlaceDetail, AwsPlaceFinder, AwsPositionFinder, AwsRoute } from '../../awsservice/aws-network-service';
import './trip-assignment.css';
import { EyeFilled, SearchOutlined, UserOutlined } from '@ant-design/icons';
import Title from 'antd/es/skeleton/Title';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { SvgMarkerFilled } from '../../component/custom-svg-icon';
import { cardStyle, DetailDescription, labelStyle } from './trip-history-detail';
import CustomPath from '../../route/custom-path';
import { Link, useNavigate, useParams } from 'react-router-dom';
dayjs.extend(customParseFormat);

const drawerTitleStyle = {
  fontSize: 20,
  fontWeight: '600',
  color: "#03ab02",
  marginBottom: 20,
}

const TripAssignUpdate = () => {
  const [form] = Form.useForm();
  const scrollRef = useRef(null);
  const isScheduled = Form.useWatch('isScheduled', form);
  const [loading, setLoading] = useState(false);
  const mapContainerRef = useRef();
  const mapRef = useRef();
  const fetchRef = useRef(0);
  const pickupMarkerRef = useRef();
  const { id } = useParams();
  const dropoffMarkerRef = useRef();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');
  let stompClient = null;
  const [inputPhoneNumber, setInputPhoneNumber] = useState();
  const [selectedPassenger, setSelectedPassenger] = useState();
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState();
  const [addressSuggestion, setAddressSuggestion] = useState([]);
  const [pickupAddress, setPickupAddress] = useState();
  const [dropoffAddress, setDropoffAddress] = useState();
  const [pickupLngLat, setPickupLngLat] = useState();
  const [dropoffLngLat, setDropoffLngLat] = useState();
  const [distance, setDistance] = useState(0);
  const [distancePickup, setDistancePickup] = useState(0);
  const [waitingDuration, setWaitingDuration] = useState(0);
  const [duration, setDuration] = useState(0);
  const [fees, setFees] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalAddressOpen, setModalAddressOpen] = useState({ open: false, label: null });
  const [carMarkers, setCarMarkers] = useState([]);
  const [driverData, setDriverData] = useState([]);
  const [region, setRegion] = useState(2);
  const [options, setOptions] = useState([]);
  const [regions, setRegions] = useState([]);
  const [isConfirmedRoute, setIsConfirmedRoute] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [passengerData, setPassengerData] = useState({});
  const [loadingId, setLoadingId] = useState()

  useDebounce(
    () => {
      if (!searchValue) return;
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      AwsPlaceFinder(searchValue)
        .then((res) => {
          if (fetchId !== fetchRef.current) return;
          setAddressSuggestion(
            res.Results?.map((result) => {
              const Text = result.Text.split(',');
              return {
                ...result,
                Text: `${Text[0]}, ${Text[1]}, ${Text[2]}, ${Text[3]}`,
              };
            }),
          );
        })
        .catch(() => { });
    },
    500,
    [searchValue],
  );

  useDebounce(
    () => {
      fetchPassengers(inputPhoneNumber);
    },
    500,
    [inputPhoneNumber],
  );

  const calculateFare = (pickupLngLat, dropoffLngLat) => {
    ApiHandler({
      url: Api.fare_calculate,
      method: HTTP_METHOD.POST,
      MediaType: MEDIA_TYPE.JSON,
      requestData: {
        pickupLatLng: pickupLngLat,
        dropOffLatLng: dropoffLngLat,
      },
      disableShowMessage: true,
    }).then((res) => {
      setFees(res.data.total);
    });
  };

  const formatTime = (seconds) => {
    const days = Math.floor(seconds / (24 * 3600));
    const hours = Math.floor((seconds % (24 * 3600)) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const timeParts = [];
    if (days > 0) timeParts.push(`${days}d`);
    if (hours > 0) timeParts.push(`${hours}h`);
    if (minutes > 0) timeParts.push(`${minutes}m`);
    if (remainingSeconds > 0 || timeParts.length === 0) timeParts.push(`${remainingSeconds.toFixed(0)}s`);

    return timeParts.join(' ');
  };

  const flyTo = (lngLat, zoom = 18) => {
    mapRef.current.flyTo({
      center: lngLat,
      zoom,
      essential: true,
      duration: 1200,
    });
  };

  const findPlace = (regionId) => {
    carMarkers?.forEach((marker) => {
      marker.markerInstance.remove();
    });
    const region = regions.find((r) => r.id === regionId);
    AwsPlaceFinder(region.regionName)
      .then((res) => {
        if (res.Results.length > 0) {
          const targetRegion = res.Results[1];
          AwsPlaceDetail(targetRegion.PlaceId)
            .then((placeDetail) => {
              const lngLat = placeDetail.Place.Geometry.Point;
              flyTo(lngLat, 11);
            })
            .catch(() => { });
        }
      })
      .catch(() => { });
  };

  const confirmRoute = (pickupLngLat, dropoffLngLat) => {
    if (mapRef.current.getLayer('route-layer')) {
      mapRef.current.removeLayer('route-layer');
    }
    if (mapRef.current.getSource('route')) {
      mapRef.current.removeSource('route');
    }
    const bounds = new mapboxgl.LngLatBounds();
    bounds.extend([pickupLngLat.lng, pickupLngLat.lat]);
    bounds.extend([dropoffLngLat.lng, dropoffLngLat.lat]);

    mapRef.current.fitBounds(bounds, {
      padding: { top: 30, bottom: 30, left: 30, right: 30 },
      maxZoom: 13,
    });
    calculateFare(pickupLngLat, dropoffLngLat);
    AwsRoute(pickupLngLat, dropoffLngLat)
      .then((res) => {
        setIsConfirmedRoute(true);
        setDistance(res.Summary.Distance);
        setDuration(res.Summary.DurationSeconds);
        const routeCoordinates = res.Legs[0].Geometry.LineString;
        mapRef.current.addSource('route', {
          type: 'geojson',
          data: {
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates: routeCoordinates,
            },
          },
        });
        mapRef.current.addLayer({
          id: 'route-layer',
          type: 'line',
          source: 'route',
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': 'rgb(33, 150, 243)',
            'line-width': 4,
          },
        });
      })
      .catch(() => { })
      .finally(() => {
      });
  };

  const connectSocket = () => {
    try {
      if (!stompClient) {
        const Sock = new SockJS(Api.driver_location_host + '/ws');
        stompClient = over(Sock);
      }

      stompClient.connect(
        {},
        () => {
          stompClient.subscribe(
            `/driver/${region}/locations`,
            (payload) => {
              onReceiveDriverLocation(payload);
            },
            (error) => {
              console.error('Subscription error:', error);
            },
          );
        },
        (error) => {
          console.error('WebSocket connection error:', error);
        },
      );
    } catch (error) {
      console.error('Error connecting to WebSocket:', error);
    }
  };

  const MarkerComponent = (marker) => {
    const el = document.createElement('div');
    el.style.width = '30px';
    el.style.height = '58px';
    el.style.backgroundSize = 'contain';
    el.style.backgroundRepeat = 'no-repeat';
    el.style.transformOrigin = 'center';

    if (marker.tripStatus === 'Active') {
      el.style.backgroundImage = 'url(/images/marker/yellow-car.png)';
    } else if (marker.driverStatus === 'Inactive' || marker.driverStatus === 'Offline') {
      el.style.backgroundImage = 'url(/images/marker/gray-car.png)';
    } else if (marker.driverStatus === 'Idle') {
      el.style.backgroundImage = 'url(/images/marker/purple-car.png)';
    } else if (marker.driverStatus === 'Available') {
      el.style.backgroundImage = 'url(/images/marker/green-car.png)';
    } else if (marker.driverStatus === 'Busy') {
      el.style.backgroundImage = 'url(/images/marker/purple-car.png)';
    } else {
      el.style.backgroundImage = 'url(/images/marker/green-car.png)';
    }

    const popupNode = document.createElement('div');
    popupNode.innerHTML = `
      <div style="display: flex; align-items: center; height: 17px">
        <img src="${marker.driverPhotoUrl}" style="width: 25px; height: 25px; border-radius: 50%; margin-right: 8px;" />
        <div style="display: flex; flex-direction: column;">
          <span style="font-weight: bold;">${marker.driverName}</span>
          <span style="font-size: small;">${marker.vehiclePlateNumber}</span>
        </div>
      </div>`;

    const initialPopup = new mapboxgl.Popup({
      offset: 25,
      closeOnClick: false,
      closeButton: false,
      openOnClick: false,
    }).setDOMContent(popupNode);

    const newMarker = new mapboxgl.Marker({ element: el })
      .setLngLat([marker.lng, marker.lat])
      .addTo(mapRef.current)
      .setPopup(initialPopup);

    newMarker.setRotation(marker.direction);
    initialPopup.addTo(mapRef.current);

    newMarker.getElement().onclick = (e) => e.stopPropagation();

    newMarker.getElement().addEventListener('click', () => {
      setIsModalOpen(true);
      setDriverData(marker);
    });

    return newMarker;
  };

  const handleSearch = (value) => {
    const filteredOptions = carMarkers
      ?.filter(
        (marker) =>
          (marker.driverName && marker.driverName.toLowerCase().includes(value.toLowerCase())) ||
          (marker.vehiclePlateNumber &&
            marker.vehiclePlateNumber
              .replace(/[^a-zA-Z0-9]/g, '')
              .toLowerCase()
              .includes(value.replace(/[^a-zA-Z0-9]/g, '').toLowerCase())),
      )
      .map((marker) => ({
        value: marker.driverName || marker.vehiclePlateNumber,
        label: `${marker.driverName} (${marker.vehiclePlateNumber ? marker.vehiclePlateNumber : 'No connected vehicle'})`,
      }));

    setOptions(filteredOptions);
  };

  const getAllDrivers = () => {
    const requestParams = {
      regionId: region,
    };
    ApiHandler({
      url: Api.driver_location,
      method: HTTP_METHOD.GET,
      MediaType: MEDIA_TYPE.JSON,
      requestParams,
    }).then((response) => {
      setCarMarkers((prevMarkers) => {
        const newMarkers = [];

        response
          .filter((driver) => driver.driverStatus === 'Available')
          .forEach((markerData) => {
            const existingMarker = prevMarkers.find((m) => m.driverId === markerData.driverId);
            if (!existingMarker) {
              const newMarker = MarkerComponent(markerData);
              newMarkers.push({
                driverId: markerData.driverId,
                lat: markerData.lat,
                lng: markerData.lng,
                markerInstance: newMarker,
                direction: markerData.direction,
                driverName: markerData.driverName,
                driverPhotoUrl: markerData.driverPhotoUrl,
                vehiclePlateNumber: markerData.vehiclePlateNumber,
              });
            }
          });

        return [...prevMarkers, ...newMarkers];
      });
    });
  };

  const onReceiveDriverLocation = useCallback(
    (payload) => {
      if (!payload) return;

      try {
        const markerData = JSON.parse(payload.body);
        if (!mapRef.current || markerData.driverStatus !== 'Available') {
          setCarMarkers((prevMarkers) => {
            const existingMarkerIndex = prevMarkers.findIndex((marker) => marker.driverId === markerData.driverId);
            console.log("exitm:", existingMarkerIndex)
            if(existingMarkerIndex){
              const existingMarker = prevMarkers[existingMarkerIndex];
              if (existingMarker?.markerInstance) {
                console.log("Removing marker:", existingMarker.driverId);
                existingMarker.markerInstance.remove();
              } else {
                console.log("No marker instance to remove");
              }
            }
            return prevMarkers.filter((marker) => marker.driverId !== markerData.driverId)
          })

        } else if (mapRef.current || markerData.driverStatus === 'Available') {
          setCarMarkers((prevMarkers) => {
            const existingMarkerIndex = prevMarkers.findIndex((marker) => marker.driverId === markerData.driverId);

            if (existingMarkerIndex !== -1) {
              const existingMarker = prevMarkers[existingMarkerIndex];
              if (existingMarker.lat !== markerData.lat || existingMarker.lng !== markerData.lng) {
                const startCoordinates = [existingMarker.lng, existingMarker.lat];
                const endCoordinates = [markerData.lng, markerData.lat];

                animateMarker(existingMarker.markerInstance, startCoordinates, endCoordinates);
              }

              existingMarker.markerInstance.setRotation(existingMarker.direction);

              const popupContent = `<div style="display: flex; align-items: center; height: 17px">
            <img src="${markerData.driverPhotoUrl}" style="width: 25px; height: 25px; border-radius: 50%; margin-right: 8px;" />
            <div style="display: flex; flex-direction: column;">
              <span style="font-weight: bold;">${markerData.driverName}</span>
              <span style="font-size: small;">${markerData.vehiclePlateNumber || '-'}</span>
            </div>
          </div>`;
              existingMarker.markerInstance.getPopup().setHTML(popupContent);

              const markerElement = existingMarker.markerInstance.getElement();
              if (markerData.tripStatus === 'Active') {
                markerElement.style.backgroundImage = 'url(/images/marker/yellow-car.png)';
              } else if (markerData.driverStatus === 'Inactive') {
                markerElement.style.backgroundImage = 'url(/images/marker/gray-car.png)';
              } else if (markerData.driverStatus === 'Idle') {
                markerElement.style.backgroundImage = 'url(/images/marker/purple-car.png)';
              } else if (markerData.driverStatus === 'Available') {
                markerElement.style.backgroundImage = 'url(/images/marker/green-car.png)';
              } else if (markerData.driverStatus === 'Busy') {
                markerElement.style.backgroundImage = 'url(/images/marker/purple-car.png)';
              } else {
                markerElement.style.backgroundImage = 'url(/images/marker/green-car.png)';
              }
              console.log("markerData", markerData)
              existingMarker.markerInstance.getElement().addEventListener('click', () => {
                setIsModalOpen(true);
                setDriverData(markerData);
              });

              existingMarker.markerInstance.setRotation(existingMarker.direction);

              return prevMarkers.map((marker, index) =>
                index === existingMarkerIndex
                  ? {
                    ...marker,
                    lat: markerData.lat,
                    lng: markerData.lng,
                    direction: markerData.direction,
                    driverName: markerData.driverName,
                    driverStatus: markerData.driverStatus,
                    driverPhotoUrl: markerData.driverPhotoUrl,
                    vehiclePlateNumber: markerData.vehiclePlateNumber || '-',
                  }
                  : marker,
              );
            } else {
              const newMarker = MarkerComponent(markerData);

              return [
                ...prevMarkers,
                {
                  driverId: markerData.driverId,
                  lat: markerData.lat,
                  lng: markerData.lng,
                  markerInstance: newMarker,
                  direction: markerData.direction,
                  driverName: markerData.driverName,
                  vehiclePlateNumber: markerData.vehiclePlateNumber || '-',
                },
              ];
            }
          });
        }
      } catch (error) {
        console.error('Error parsing WebSocket data:', error);
      }
    },
    [mapRef, setCarMarkers],
  );

  const animateMarker = (markerInstance, startCoordinates, endCoordinates) => {
    const startTime = performance.now();
    const duration = 1000;

    const animate = (currentTime) => {
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / duration, 1);

      const newLng = startCoordinates[0] + (endCoordinates[0] - startCoordinates[0]) * progress;
      const newLat = startCoordinates[1] + (endCoordinates[1] - startCoordinates[1]) * progress;

      if (!isNaN(newLng) && !isNaN(newLat)) {
        markerInstance.setLngLat([newLng, newLat]);
      }

      if (progress < 1) {
        requestAnimationFrame(animate);
      }
    };

    requestAnimationFrame(animate);
  };

  const handleSelect = (driverName) => {
    const requestParams = {
      driverName: driverName.trim(),
    };

    const matchMarker = carMarkers.find((marker) => marker.driverName === requestParams.driverName);

    if (matchMarker && mapRef.current) {
      mapRef.current.flyTo({
        center: [matchMarker.lng, matchMarker.lat],
        zoom: 14,
        essential: true,
        duration: 1200,
      });
    }
  };

  const fetchRegions = () => {
    ApiHandler({
      url: Api.region_filter,
      method: HTTP_METHOD.GET,
      MediaType: MEDIA_TYPE.JSON,
    }).then((res) => {
      setRegions(res?.content);
      if (res?.content) {
        const defaultRegion = res.content.find((r) => r.regionName === 'Yangon');
        if (defaultRegion) {
          setRegion(defaultRegion.id);
          form.setFieldValue('region', defaultRegion.id);
        } else {
          setRegion(res?.content[0]?.id);
          form.setFieldValue('region', res?.content[0]?.id);
        }
      }
      connectSocket();
    });
  };

  useEffectOnce(() => {
    fetchRegions();
    getAllDrivers();
  });

  const fetchPaymentMethods = () => {
    ApiHandler({
      url: Api.payment_methods,
      method: HTTP_METHOD.GET,
      MediaType: MEDIA_TYPE.JSON,
    })
      .then((res) => {
        setPaymentMethods(res);
      })
      .catch(() => { });
  };

  // const assignDriver = (driver) => {
  //   let isScheduled = form.getFieldValue('isScheduled') ? true : false
  //   let scheduleTime = form.getFieldValue('scheduleTime')?.format('YYYY-MM-DD HH:mm:A')
  //   console.log("paymemethod:", paymentMethod)
  //   console.log('isSchedule:', isScheduled)

  //   // await setSelectedDriver(driver);
  //   updateTripAssign({
  //     isScheduled: form.getFieldValue('isScheduled') ? true : false,
  //     scheduleTime: scheduleTime,
  //     paymentMethod: paymentMethod,
  //     region: region,
  //   }, driver);
  // }

  const backToHome = () => navigate(CustomPath.trip_dispatcher);

  const assignDriverToTrip = (driverId, tripId) => {
    console.log("tripId:", tripId)
    console.log("driverId:", driverId)
    setLoadingId(driverId)

    const url = Api.trip_instant_assign_driver_to_trip + '/' + tripId + '/' + driverId;
    ApiHandler({ url, method: HTTP_METHOD.PUT, disableShowMessage: true })
      .then(() => {
        message.success('Successfully assigned to the selected trip');
        const filteredData = carMarkers.filter((item) => item.driverId !== driverId);
          setCarMarkers(filteredData)
        backToHome()
      })
      .catch(() => { })
      .finally(() => {
        setLoadingId(null)
      });
  };

  const fetchPassengers = (phoneNumber) => {
    PhoneNumberValidator(null, phoneNumber)
      .then(() => {
        const requestParams = {
          size: 20,
          page: 0,
          phoneNumber,
        };
        ApiHandler({
          url: Api.passenger_filter,
          method: HTTP_METHOD.GET,
          MediaType: MEDIA_TYPE.JSON,
          requestParams,
        })
          .then((response) => {
            setSelectedPassenger(response?.content[0]);
          })
          .catch(() => { });
      })
      .catch(() => { });
  };

  const convertToEstimatedLabel = (distance) => {
    return `${(Math.round(distance * 100) / 100).toFixed(2)} Kilometers`;
  };

  const initMap = () => {
    mapboxgl.accessToken = mapBoxAccessToken;
    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [96.14402811407751, 16.88561065060059],
      zoom: 15,
    });
    mapRef.current.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
  };

  const addPickupMarker = (lnglat) => {
    pickupMarkerRef.current = new mapboxgl.Marker({
      // draggable: true,
      color: '#52c41a',
    })
      .setLngLat(lnglat)
      .addTo(mapRef.current);

    pickupMarkerRef.current.getElement().onclick = (e) => e.stopPropagation();

    pickupMarkerRef.current.getElement().addEventListener('click', () => {
      setModalAddressOpen({ open: true, label: pickupAddress?.label });
    });
  };

  const addDropoffMarker = (lnglat) => {
    dropoffMarkerRef.current = new mapboxgl.Marker({
      // draggable: true,
      color: '#f5222d',
    })
      .setLngLat(lnglat)
      .addTo(mapRef.current);
    dropoffMarkerRef.current.getElement().onclick = (e) => e.stopPropagation();

    dropoffMarkerRef.current.getElement().addEventListener('click', () => {
      setModalAddressOpen({ open: true, label: dropoffAddress?.label });
    });
  };

  useEffect(() => {
    initMap();
    fetchPaymentMethods();
    ApiHandler({
      url: Api.trip_history,
      method: HTTP_METHOD.GET,
      specificId: id,
    })
      .then((res) => {
        console.log("res:", res)

        const pickupAddress = res.tripPickUpLocation.address.split(',');
        setPickupAddress({
          label: `${pickupAddress[0]}, ${pickupAddress[1]}, ${pickupAddress[2]}, ${pickupAddress[3]}`,
        });
        const dropoffAddress = res.tripDropOffLocation.address.split(',');

        setDropoffAddress({
          label: `${dropoffAddress[0]}, ${dropoffAddress[1]}, ${dropoffAddress[2]}, ${dropoffAddress[3]}`,
        });
        setPassengerData(res.passengerDTO);

        const pickupPoints = {
          lat: res.tripPickUpLocation.latitude,
          lng: res.tripPickUpLocation.longitude,
        };
        const dropoffPoints = {
          lat: res.tripDropOffLocation.latitude,
          lng: res.tripDropOffLocation.longitude,
        };
        setPickupLngLat(pickupPoints);
        setDropoffLngLat(dropoffPoints);

        addPickupMarker([pickupPoints.lng, pickupPoints.lat]);
        addDropoffMarker([dropoffPoints.lng, dropoffPoints.lat]);
        confirmRoute(pickupPoints, dropoffPoints);

        setSelectedPassenger(res.passengerDTO);
        form.setFieldValue('paymentMethod', res.paymentMethod);
        form.setFieldValue('scheduleTime', dayjs(res.scheduleTime));
        form.setFieldValue('isScheduled', res.scheduled);
        form.setFieldValue('scheduledDriver', { label: res.driverDTO.fullName, value: res.driverDTO.id });
        mapRef.current.on('load', () => {
        });
      })

      .catch(() => { });
    return () => {
      if (mapRef.current) mapRef.current.remove();
    };
  }, [id]);

  const driverLabel = [
    {
      key: 1,
      label: 'Driver Name: ',
      children: selectedDriver?.fullName ?? '-',
    },
    {
      key: 2,
      label: 'Phone Number: ',
      children: selectedDriver?.phoneNumber ?? '-',
    },
    {
      key: 3,
      label: 'Vehicle Plate Number: ',
      children: selectedDriver?.vehiclePlateNumber ?? '-',
    },
  ];

  const tripRouteLabel = [
    {
      key: 1,
      label: 'Estimated Distance: ',
      children: convertToEstimatedLabel(distance),
    },
    {
      key: 2,
      label: 'Estimate Time: ',
      children: formatTime(duration),
    },
    {
      key: 3,
      label: 'Fees: ',
      children: `${`${fees}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} Kyat`,
    },
    {
      key: 4,
      label: 'Distance Pick-Up',
      children: convertToEstimatedLabel(distancePickup),
    },
    {
      key: 5,
      label: 'Duration Pick-Up',
      children: formatTime(waitingDuration),
    },
  ];

  const haversineDistance = (lat1, lon1, lat2, lon2) => {
    const toRadians = (degrees) => (degrees * Math.PI) / 180;
    const R = 6371;

    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) ** 2 +
      Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) * Math.sin(dLon / 2) ** 2;

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  };

  const sortedMarkers = carMarkers.map((driver) => ({
    ...driver,
    distance: haversineDistance(driver?.lat, driver?.lng, pickupLngLat?.lat, pickupLngLat?.lng),
  })).sort((a, b) => a.distance - b.distance);

  return (
    <>
      <Drawer open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        <div style={drawerTitleStyle}>Active Drivers</div>
        {sortedMarkers.map((driver) => (
          <div key={driver.driverId}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Avatar src={driver.driverPhotoUrl} style={{ marginRight: 10 }} />
                <div>
                  <div>{driver.driverName}</div>
                  <span style={{ fontSize: '13px', color: 'gray' }}>{driver.vehiclePlateNumber}</span>
                </div>
              </div>
              <Button loading={loadingId === driver.driverId ? true : false} onClick={() => assignDriverToTrip(driver.driverId, id)} type='primary'>Assign</Button>
            </div>
            <Divider style={{ margin: "15px 0" }} />
          </div>
        ))}
      </Drawer>
      <Row gutter={[16, 16]}>
        <Col span={10}>
          <Breadcrumb
            items={[
              {
                title: 'Home',
              },
              {
                title: <Link to={CustomPath.trip_dispatcher}>Dispatcher</Link>,
              },
              {
                title: 'Trip Assign',
              },
            ]}
          />
        </Col>
        <Col span={14} style={{ textAlign: 'end' }}>
          <Link to={CustomPath.trip_dispatcher}>
            <Button type="primary">Back</Button>
          </Link>
        </Col>
        <Col span={24}>
          <Card
            style={{
              backgroundColor: Theme.colors.card_bg_color,
              boxShadow: 'none',
            }}
            bordered={false}
            title={(<div style={{ display: 'flex', justifyContent: 'space-between' }}>Trip Assignment<Button
              type='primary' style={{ fontSize: '14px', height: '35px' }}
              onClick={() => setIsDrawerOpen(true)}>
              Assign Driver
            </Button>
            </div>)}
          >
            <Form layout="vertical" form={form}>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    name="paymentMethod"
                    label={'Payment Method'}
                    rules={[{ required: true, message: '*(requried)' }]} //
                  >
                    <NJVSelect placeholder="Select Payment Method" disabled={true}>
                      {paymentMethods.map((paymentMethod) => {
                        // console.log("payment:", paymentMethod.value)
                        return (
                          <Select.Option key={paymentMethod.key} value={paymentMethod.key}>
                            {paymentMethod.value}
                          </Select.Option>
                        )
                      })}
                    </NJVSelect>
                  </Form.Item>
                </Col>
                {!selectedPassenger && (
                  <>
                    <Col span={12}>
                      <Form.Item
                        name="firstName"
                        label={'First Name'}
                        rules={[{ required: true, message: '*(requried)' }]}
                      >
                        <NJVInput placeholder={'Enter First Name'} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="lastName" label={'Last Name'}>
                        <NJVInput placeholder={'Enter Last Name'} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="gender" label={'Gender'}>
                        <Radio.Group>
                          <Radio value={'Male'}>Male</Radio>
                          <Radio value={'Female'}>Female</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="dateOfBirth" label={'Date Of Birth'}>
                        <NJVDatePicker placeholder={'Select Date Of Birth'} style={{ width: '100%' }}></NJVDatePicker>
                      </Form.Item>
                    </Col>
                  </>
                )}
                <Col span={8}>
                  <Form.Item
                    shouldUpdate={(prevValues, currentValues) => {
                      return prevValues.isScheduled !== currentValues.isScheduled;
                    }}
                  >
                    {({ getFieldValue }) => (
                      <Form.Item
                        label="Pick-Up Date Time"
                        name="scheduleTime"
                        rules={[{ required: true, message: '*(requried)' }]}
                      >
                        {getFieldValue('scheduleTime')?.format('YYYY-MM-DD hh:mm A')}
                      </Form.Item>
                    )}
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name="isScheduled" valuePropName="checked" label="Booking Type">
                    {isScheduled ? 'Scheduled Trip' : 'Now Trip'}
                  </Form.Item>
                </Col>
              </Row>
              {selectedPassenger && (
                <div style={{ ...cardStyle, marginBottom: 20 }}>
                  <Row gutter={[16, 16]}>
                    <Col span={6}>
                      <Form.Item label={'Passenger Name'}>
                        <Typography.Text>{`${selectedPassenger?.firstName} ${selectedPassenger?.lastName ?? ''}`}</Typography.Text>
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label={'Passenger Phone Number'}>
                        <Typography.Text>{`${selectedPassenger?.phoneNumber}`}</Typography.Text>
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label={'Gender'}>
                        <Typography.Text>{selectedPassenger?.gender ?? '-'}</Typography.Text>
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label={'Date Of Birth'}>
                        <Typography.Text>{selectedPassenger?.dateOfBirth ?? '-'}</Typography.Text>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              )}
              <div style={{ ...cardStyle, marginBottom: 20, paddingBottom: '18px' }}>
                <div style={{ ...cardStyle, paddingBottom: '18px' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <SvgMarkerFilled width={25} height={25} color={'#52c41a'} />
                    <div style={{ marginLeft: 15, width: '100%' }}>
                      <div style={{ ...labelStyle }}>Pick-Up Address</div>
                      <div style={{}}>{pickupAddress?.label ?? 'Please select a pick-up address.'}</div>
                    </div>
                  </div>
                </div>
                <div style={{ height: 30, borderLeft: '4px dotted gray', marginLeft: 50 }} />
                <div style={cardStyle}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <SvgMarkerFilled width={25} height={25} color={'#f5222d'} />
                    <div style={{ marginLeft: 15, width: '100%' }}>
                      <div style={{ ...labelStyle }}>Drop-Off Address</div>
                      <div style={{}}>{dropoffAddress?.label ?? 'Please select a drop-off address.'}</div>
                    </div>
                  </div>
                </div>
              </div>
              <Row gutter={[16, 16]} style={{ marginBottom: 20 }}>
                <Col span={12}>
                  <DetailDescription
                    label={isScheduled ? tripRouteLabel.filter(({ key }) => key !== 4 && key !== 5) : tripRouteLabel}
                  />
                </Col>
                <Col span={12}>
                  <Form.Item
                    shouldUpdate={(prevValues, currentValues) => {
                      return prevValues.isScheduled !== currentValues.isScheduled;
                    }}
                  >
                    {({ getFieldValue }) => {
                      return !getFieldValue('isScheduled') ? <DetailDescription label={driverLabel} /> : null;
                    }}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24} ref={scrollRef}>
                  <Form.Item
                    style={{
                      height: 800,
                    }}
                  >
                    <Card
                      ref={mapContainerRef}
                      style={{
                        height: 800,
                      }}
                    />
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                      <Form.Item
                        style={{ width: '100%' }}
                        shouldUpdate={(prevValues, currentValues) => {
                          return prevValues.isScheduled !== currentValues.isScheduled;
                        }}
                      >
                        {({ getFieldValue }) => (
                          <Row gutter={[20, 20]} style={{ marginTop: 10, marginLeft: 10 }}>
                            <Col span={5} style={{ height: 10 }}>
                              <Form layout="horizontal">
                                <Form.Item name="driverName">
                                  <AutoComplete
                                    options={options}
                                    onSearch={handleSearch}
                                    onSelect={handleSelect}
                                    style={{ width: '100%' }}
                                  >
                                    <Input
                                      style={{
                                        height: 40,
                                        boxShadow: 'none',
                                        borderColor: 'white',
                                      }}
                                      suffix={<SearchOutlined style={{ color: '#656566' }} />}
                                      placeholder="Search by DriverName or VehicleNumber"
                                    />
                                  </AutoComplete>
                                </Form.Item>
                              </Form>
                            </Col>
                            <Col span={4}>
                              <Form.Item name="region">
                                <Select
                                  // disabled={getFieldValue('isScheduled')}
                                  size="large"
                                  style={{ width: '100%' }}
                                  placeholder="Select Region"
                                  onChange={(v) => {
                                    setRegion(v);
                                    findPlace(v);
                                    connectSocket();
                                  }}
                                  showSearch
                                >
                                  {regions.map((reg) => (
                                    <Select.Option value={reg.id} key={reg.id}>
                                      {reg.regionName}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col span={4}>
                              <NJVButton type='primary' style={{ fontSize: '15px' }} onClick={() => setIsDrawerOpen(true)}>Active Driver List</NJVButton>
                            </Col>
                          </Row>
                        )}
                      </Form.Item>
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
        <Modal open={isModalOpen} footer={false} onCancel={() => setIsModalOpen(false)} closable={true} width={300}>
          <div style={{ textAlign: 'center' }}>
            <Avatar
              src={driverData.driverPhotoUrl}
              size={80}
              icon={!driverData.driverPhotoUrl ? <UserOutlined /> : undefined}
            />

            <Title level={4}>{driverData.driverName}</Title>
            <Typography.Text strong>License Plate Number: {driverData.vehiclePlateNumber}</Typography.Text>
            <div style={{ marginTop: '8px' }}>
              <Typography.Text strong>
                Driver Status:{' '}
                {driverData?.driverStatus === 'Available' ? (
                  <Tag color="green">{driverData.driverStatus}</Tag>
                ) : (
                  driverData?.driverStatus
                )}
              </Typography.Text>
            </div>
            {/* {form.getFieldValue('isScheduled') && (
              <NJVButton
                type="primary"
                style={{ width: '50%', marginTop: 10 }}
                onClick={() => {
                  findDriverById(driverData);
                  setIsModalOpen(false);
                }}
              >
                Assign
              </NJVButton>
            )} */}
          </div>
        </Modal>
        <Modal
          open={isModalAddressOpen.open}
          footer={false}
          onCancel={() => setModalAddressOpen({ open: false, label: null })}
          closable={true}
          width={400}
        >
          <div style={{ textAlign: 'center' }}>
            <Typography.Title level={5}>
              {' '}
              <SvgMarkerFilled width={15} height={15} color={'rgb(100, 181, 246)'} /> Address
            </Typography.Title>
            <Typography.Text strong> {isModalAddressOpen?.label}</Typography.Text>
          </div>
        </Modal>
      </Row>
    </>
  );
};

export default TripAssignUpdate;
