import React from 'react';
import { NJVButton } from '../component/core-component';

const jsonDatas = [
  { lat: 40.44356, lng: -79.91746, dbh: 0 },
  { lat: 40.44961, lng: -79.94606, dbh: 12 },
  { lat: 40.46283, lng: -79.96474, dbh: 6 },
];

const GeoJsonExport = () => {
  const geoJson = {
    type: 'FeatureCollection',
    features: jsonDatas.map((point) => ({
      type: 'Feature',
      properties: {
        dbh: point.dbh || 0,
      },
      geometry: {
        type: 'Point',
        coordinates: [point.lng, point.lat],
      },
    })),
  };

  const handleExportClick = () => {
    const blob = new Blob([JSON.stringify(geoJson)], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'geojson.json';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  return (
    <>
      <NJVButton onClick={handleExportClick} type="primay" style={{ backgroundColor: '#53db8e', color: '#fff' }}>
        Export Geojson
      </NJVButton>
    </>
  );
};

export default GeoJsonExport;
