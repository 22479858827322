import { Card, Col, Row, Statistic } from 'antd';
import React, { useEffect, useState } from 'react';
import { ApiHandler } from '../../network/network-manager';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import Api from '../../network/api';
import CountUp from 'react-countup';
import { validate } from 'uuid';
import CustomPath from '../../route/custom-path';
import { Link } from 'react-router-dom';

const titleStyle = {
  fontSize: 20,
  fontWeight: '600',
};
const subtitleStyle = {
  fontSize: 17,
  fontWeight: '500',
};

const countTitleStyle = {
  fontSize: 20,
  fontWeight: '600',
  color: "#8a8a8a",
  minWidth: "280px"
}

const CountCard = ({ name, value }) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      gap: "10px",
      marginRight: '20px',
    }}
  >
    <div style={countTitleStyle}>{name}</div>
    <Statistic value={value} valueStyle={{
      color: '#3f8600',
      fontSize: 19,
    }} />
  </div>
);

const formatter = (value) => <CountUp end={value} separator="," />;
const Dashboard = () => {
  const [incomeSummary, setIncomeSummary] = useState({});
  const [count, setCount] = useState({});

  useEffect(() => {
    ApiHandler({
      url: Api.daily_income_summary,
      method: HTTP_METHOD.GET,
      MediaType: MEDIA_TYPE.JSON,
    })
      .then((response) => {
        setIncomeSummary(response);
      })
      .catch(() => { })
      .finally(() => { });
  }, []);

  useEffect(() => {
    ApiHandler({
      url: Api.landing_page_report,
      method: HTTP_METHOD.GET,
      MediaType: MEDIA_TYPE.JSON,
    })
      .then((response) => {
        setCount(response);
      })
      .catch(() => { })
      .finally(() => { });
  }, []);
  return (
    <Row gutter={[16, 16]}>
      <Col md={8} xs={24}>
        <Link to={CustomPath.incentive_history}>
          <Card bordered={false} className="card-shadow">
            <Row gutter={[16, 15]}>
              <Col span={24}>
                <Statistic
                  title={<span style={titleStyle}>Today Income</span>}
                  value={incomeSummary?.totalIncome || 0}
                  valueStyle={{
                    color: '#3f8600',
                    fontSize: 25,
                  }}
                  suffix={<span style={{ fontSize: 21 }}>MMK</span>}
                  formatter={formatter}
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title={<div style={subtitleStyle}>Cash</div>}
                  value={incomeSummary?.cashAmount || 0}
                  valueStyle={{
                    color: '#3f8600',
                    fontSize: 19,
                  }}
                  suffix={<span style={{ fontSize: 15 }}>MMK</span>}
                  formatter={formatter}
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title={<div style={subtitleStyle}>KPay</div>}
                  value={incomeSummary?.kpayAmount || 0}
                  valueStyle={{
                    color: '#3f8600',
                    fontSize: 19,
                  }}
                  suffix={<span style={{ fontSize: 15 }}>MMK</span>}
                  formatter={formatter}
                />
              </Col>
            </Row>
          </Card>
        </Link>
      </Col>
      <Col md={16} xs={0} />
      <Col md={8} xs={12}>
        <Link to={CustomPath.trip_history}>
          <Card bordered={false} className="card-shadow">
            <Row gutter={[16, 15]}>
              <Col span={24}>
                <span style={countTitleStyle}>Completed Trip</span>
              </Col>
              <Col span={12}>
                <Statistic
                  // title={<div style={subtitleStyle}>Count</div>}
                  value={count?.completedTripCount || 0}
                  valueStyle={{
                    color: '#3f8600',
                    fontSize: 19,
                  }}
                  suffix={<span style={{ fontSize: 15 }}></span>}
                  formatter={formatter}
                />
              </Col>
            </Row>
          </Card>
        </Link>
      </Col>
      <Col md={8} xs={12}>
        <Link to={CustomPath.trip_history}>
          <Card bordered={false} className="card-shadow">
            <Row gutter={[16, 15]}>
              <Col span={24}>
                <span style={countTitleStyle}>Ongoing Trip</span>
              </Col>
              <Col span={12}>
                <Statistic
                  // title={<div style={subtitleStyle}>Count</div>}
                  value={count?.ongoingTripCount || 0}
                  valueStyle={{
                    color: '#3f8600',
                    fontSize: 19,
                  }}
                  suffix={<span style={{ fontSize: 15 }}></span>}
                  formatter={formatter}
                />
              </Col>
            </Row>
          </Card>
        </Link>
      </Col>
      <Col md={8} xs={0} />
      <Col md={8} xs={12} >
        <Link to={CustomPath.active_driver_list}>
          <Card bordered={false} className="card-shadow">
            <Row gutter={[16, 15]}>
              <Col span={24}>
                <span style={countTitleStyle}>Active Driver</span>
              </Col>
              <Col span={12}>
                <Statistic
                  // title={<div style={subtitleStyle}>Count</div>}
                  value={count?.activeDriverCount || 0}
                  valueStyle={{
                    color: '#3f8600',
                    fontSize: 19,
                  }}
                  suffix={<span style={{ fontSize: 15 }}></span>}
                  formatter={formatter}
                />
              </Col>
            </Row>
          </Card>
        </Link>
      </Col>
      <Col md={0} xs={12} />
      <Col md={8} xs={12}>
        <Link to={CustomPath.driver_vehicle_availability_check}>
          <Card bordered={false} className="card-shadow">
            <Row gutter={[16, 15]}>
              <Col span={24}>
                <span style={countTitleStyle}>Assigned Driver</span>
              </Col>
              <Col span={12}>
                <Statistic
                  // title={<div style={subtitleStyle}>Count</div>}
                  value={count?.assignedDriverCount || 0}
                  valueStyle={{
                    color: '#3f8600',
                    fontSize: 19,
                  }}
                  suffix={<span style={{ fontSize: 15 }}></span>}
                  formatter={formatter}
                />
              </Col>
            </Row>
          </Card>
        </Link>
      </Col>
      <Col md={8} xs={12}>
        <Link to={CustomPath.driver_vehicle_availability_check}>
          <Card bordered={false} className="card-shadow">
            <Row gutter={[16, 15]}>
              <Col span={24}>
                <span style={countTitleStyle}>Unassigned Driver</span>
              </Col>
              <Col span={12}>
                <Statistic
                  // title={<div style={subtitleStyle}>Count</div>}
                  value={count?.unassignedDriverCount || 0}
                  valueStyle={{
                    color: '#3f8600',
                    fontSize: 19,
                  }}
                  suffix={<span style={{ fontSize: 15 }}></span>}
                  formatter={formatter}
                />
              </Col>
            </Row>
          </Card>
        </Link>
      </Col>
    </Row>

  );
};

export default Dashboard;



