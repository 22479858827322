import { Col, Row } from 'antd';
import mapboxgl from 'mapbox-gl';
import React, { useEffect, useRef } from 'react';
import { SvgMarkerRoute } from '../../component/custom-svg-icon';
import Theme from '../../component/theme';
import { mapBoxAccessToken } from '../../core/constant';

mapboxgl.accessToken = mapBoxAccessToken;

const RouteMap = ({ travelRouteCoordinates, pickUpRouteCoordinates }) => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);

  const travelCoordinates = travelRouteCoordinates?.map((route) => {
    return [route.coordinates[0], route.coordinates[1]];
  });

  const pickUpCoordinates = pickUpRouteCoordinates?.map((route) => {
    return [route.coordinates[0], route.coordinates[1]];
  });

  const centerCoordinate =
    travelCoordinates && travelCoordinates?.length > 1
      ? [
          (travelCoordinates[0][0] + travelCoordinates[travelCoordinates?.length - 1][0]) / 2,
          (travelCoordinates[0][1] + travelCoordinates[travelCoordinates?.length - 1][1]) / 2,
        ]
      : travelCoordinates[0];

  useEffect(() => {
    if (!travelCoordinates) return;
    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: centerCoordinate,
      zoom: 14,
    });

    new mapboxgl.Marker({ color: Theme.colors.green }).setLngLat(travelCoordinates[0]).addTo(mapRef.current);

    new mapboxgl.Marker({ color: 'red' })
      .setLngLat(travelCoordinates[travelCoordinates.length - 1])
      .addTo(mapRef.current);
    if (pickUpCoordinates?.length > 1) {
      new mapboxgl.Marker({ color: Theme.colors.system_blue }).setLngLat(pickUpCoordinates[0]).addTo(mapRef.current);

      new mapboxgl.Marker({ color: Theme.colors.red_orange })
        .setLngLat(pickUpCoordinates[pickUpCoordinates.length - 1])
        .addTo(mapRef.current);
    }

    mapRef.current.on('load', () => {
      mapRef.current.addSource('route', {
        type: 'geojson',
        data: {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: travelCoordinates,
          },
        },
      });

      mapRef.current.addSource('pickup-route', {
        type: 'geojson',
        data: {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: pickUpCoordinates,
          },
        },
      });

      mapRef.current.addLayer({
        id: 'route-line',
        type: 'line',
        source: 'route',
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': Theme.colors.red,
          'line-width': 4,
          'icon-image': ['coalesce', ['image', 'myImage'], ['image', 'fallbackImage']],
        },
      });
      mapRef.current.addLayer({
        id: 'pickup-route-line',
        type: 'line',
        source: 'pickup-route',
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': Theme.colors.default,
          'line-width': 4,
        },
      });
    });

    return () => mapRef.current?.remove();
  }, []);
  return (
    <>
      <div ref={mapContainerRef} style={{ width: '100%', height: '100%' }} />
      {pickUpCoordinates?.length > 1 ? (
        <>
          <div
            style={{
              width: 180,
              margin: '10px 0px 0px 10px',
              backgroundColor: 'white',
              position: 'fixed',
              padding: '8px',
              borderRadius: '10px',
            }}
          >
            <Row style={{ fontSize: '10px' }}>
              <Col span={24} style={{ display: 'flex' }}>
                <div
                  style={{ backgroundColor: Theme.colors.default, width: 18, height: 9, margin: '7px 5px 0 1.5px' }}
                ></div>
                Map Suggestion Route
              </Col>
              <Col span={24}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: 4 }}>
                    <SvgMarkerRoute width={20} height={20} color={Theme.colors.system_blue} />
                  </span>
                  PickUp Location
                </div>
              </Col>
              <Col span={24}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: 4 }}>
                    <SvgMarkerRoute width={20} height={20} color={Theme.colors.red_orange} />
                  </span>
                  DropOff Location
                </div>
              </Col>
            </Row>
          </div>
          <div
            style={{
              width: 180,
              margin: '105px 0px 0px 10px',
              backgroundColor: 'white',
              position: 'fixed',
              padding: '8px',
              borderRadius: '10px',
            }}
          >
            <Row style={{ fontSize: '10px' }}>
              <Col span={24} style={{ display: 'flex' }}>
                <div
                  style={{ backgroundColor: Theme.colors.red, width: 18, height: 9, margin: '7px 5px 0 1.5px' }}
                ></div>
                Actual Travelled Route
              </Col>
              <Col span={24}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: 4 }}>
                    <SvgMarkerRoute width={20} height={20} color={Theme.colors.soft_green} />
                  </span>
                  PickUp Location
                </div>
              </Col>
              <Col span={24}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: 4 }}>
                    <SvgMarkerRoute width={20} height={20} color={Theme.colors.red} />
                  </span>
                  DropOff Location
                </div>
              </Col>
            </Row>
          </div>
        </>
      ) : (
        <div
          style={{
            width: 180,
            margin: '10px 0px 0px 10px',
            backgroundColor: 'white',
            position: 'fixed',
            padding: '8px',
            borderRadius: '10px',
          }}
        >
          <Row style={{ fontSize: '10px' }}>
            <Col span={24}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: 4 }}>
                  <SvgMarkerRoute width={20} height={20} color={Theme.colors.soft_green} />
                </span>
                PickUp Location
              </div>
            </Col>
            <Col span={24}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: 4 }}>
                  <SvgMarkerRoute width={20} height={20} color={Theme.colors.red} />
                </span>
                DropOff Location
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default RouteMap;
