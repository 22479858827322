import { Button, Col, Modal, Row, Skeleton, Table } from 'antd';
import { Constant } from '../../core/constant';
import { useEffect, useState } from 'react';
import { MinusCircleFilled, PlusOutlined } from '@ant-design/icons';
import { ApiHandler } from '../../network/network-manager';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import NotificationUnSubcribersList from './notification-unsubcribers-list';
const getRowClassName = (record, index) => {
  return index % 2 === 0 ? 'blue-row' : 'black-row';
};

const NotificationSubcribersList = ({ channelId, userType }) => {
  const pageSize = Constant.pageSize;

  const [page, setPage] = useState(0);
  const [totalPagination, setTotalPagination] = useState(0);
  const [userList, setUserList] = useState([]);

  const [fetching, setFetching] = useState(false);
  const [removingUser, setRemovingUser] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    fetchSubscriptList();
  }, []);

  const fetchSubscriptList = (pageNumber) => {
    setFetching(true);
    const requestParams = {
      page: pageNumber ? pageNumber - 1 : page,
      size: pageSize,
      userType,
    };
    ApiHandler({
      url: Api.notification_channel_get_subscribe_users,
      method: HTTP_METHOD.GET,
      specificId: channelId,
      requestParams,
    })
      .then((response) => {
        const temp = response?.content?.map((item) => ({ ...item, key: item.id }));
        setUserList(temp);
        setPage(pageNumber ? pageNumber - 1 : page);
        setTotalPagination(response?.totalElements);
      })
      .catch(() => { })
      .finally(() => setFetching(false));
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const handlePaginationChange = (pageNumber) => {
    fetchSubscriptList(pageNumber);
  };

  const removeUsersFromChannel = () => {
    setRemovingUser(true);
    const requestData = {
      channelId,
      userIds: selectedRowKeys,
    };

    ApiHandler({
      url: Api.notification_channel_remove_members,
      method: HTTP_METHOD.POST,
      requestData,
      disableShowMessage: true,
    })
      .then(() => {
        fetchSubscriptList();
        setSelectedRowKeys([]);
      })
      .catch(() => { })
      .finally(() => setRemovingUser(false));
  };
  const columns = [
    {
      title: 'No.',
      render: (value, item, index) => <span>{page * pageSize + index + 1}</span>,
      width: 5,
    },
    {
      title: 'Name',
      dataIndex: 'fullName',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
    },
  ];
  const usersAdded = () => {
    setOpenModal(false);
    fetchSubscriptList();
  };
  return (
    <>
      <Modal
        title="Add Users to Channel"
        style={{ top: 20, padding: 0, left: 0, bottom: 20 }}
        width="100%"
        open={openModal}
        onCancel={() => setOpenModal(false)}
        footer={null}
      >
        <NotificationUnSubcribersList
          channelId={channelId}
          userType={userType}
          openModal={openModal}
          onAddedUsers={() => usersAdded()}
        />
      </Modal>
      <Row gutter={[16, 16]}>
        <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            type="primary"
            disabled={selectedRowKeys?.length === 0}
            onClick={() => removeUsersFromChannel()}
            icon={<MinusCircleFilled />}
            loading={removingUser}
          >
            Remove User
          </Button>
          <Button type="primary" onClick={() => setOpenModal(true)} icon={<PlusOutlined />}>
            Add More User
          </Button>
        </Col>
        <Col span={24}>
          {fetching ? (
            <Skeleton active />
          ) : (
            <Table
              rowSelection={{
                selectedRowKeys,
                onChange: onSelectChange,
              }}
              style={{ borderRadius: 10 }}
              pagination={{
                position: 'bottom',
                total: totalPagination,
                current: page + 1,
                defaultPageSize: pageSize,
                showSizeChanger: false,
                onChange: handlePaginationChange,
              }}
              columns={columns}
              dataSource={userList}
              rowClassName={getRowClassName}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default NotificationSubcribersList;
