import { Avatar, Col, Empty, Pagination, Row, Skeleton, Tag } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import defaultProfile from '../../../assets/default_profile.png';
import { NJVButton, NJVCard, NJVInput, TitleLevel3 } from '../../../component/core-component';
import Theme from '../../../component/theme';
import Api from '../../../network/api';
import { HTTP_METHOD } from '../../../network/httpMethod';
import { MEDIA_TYPE } from '../../../network/mediaType';
import { ApiHandler } from '../../../network/network-manager';
import CustomPath from '../../../route/custom-path';
import SockJS from 'sockjs-client';
import { over } from 'stompjs';

const driverStatusTagStyle = {
  borderRadius: '20px',
  padding: '2px 15px',
  fontSize: 13,
  fontWeight: '600',
  letterSpacing: 0.5,
};
const ActiveDriverList = () => {
  const [page, setPage] = useState(0);
  const [dataFetching, setDataFetching] = useState(false);
  const [data, setData] = useState([]);
  const [region, setRegion] = useState(2);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [filterValue, setFilterValue] = useState('');
  const pageSize = 15;
  const [socketConnected, setSocketConnected] = useState(false);
  let stompClient = null;

  const filteredData = data?.filter(
    (item) =>
      item?.driverName?.toLowerCase().includes(filterValue?.toLowerCase()) ||
      item?.vehiclePlateNumber
        ?.toLowerCase()
        .replace(/[^a-z0-9]/g, '') // Remove non-alphanumeric characters
        .includes(filterValue?.toLowerCase().replace(/[^a-z0-9]/g, '')),
  );

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const actualData = filteredData.slice(startIndex, endIndex);

  const fetchData = useCallback((pageNumber) => {
    const requestParams = {
      size: pageSize,
      page: pageNumber ? pageNumber - 1 : page,
      regionId: region,
    };

    ApiHandler({
      url: Api.driver_location,
      method: HTTP_METHOD.GET,
      MediaType: MEDIA_TYPE.JSON,
      requestParams,
    })
      .then((response) => {
        setData(response);
      })
      .catch(() => {})
      .finally(() => setDataFetching(false));
  }, []);

  useEffect(() => {
    setDataFetching(true);
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (!socketConnected) {
      connectSocket();
    }
  }, [socketConnected, region]);

  const connectSocket = () => {
    try {
      const Sock = new SockJS(Api.driver_location_host + '/ws');
      stompClient = over(Sock);

      stompClient.connect(
        {},
        () => {
          setSocketConnected(true);
          console.log('Connected to WebSocket');

          stompClient.subscribe(
            `/driver/${region}/locations`,
            (payload) => {
              onReceiveDriverLocation(payload);
            },
            (error) => {
              console.error('Subscription error:', error);
            },
          );
        },
        (error) => {
          setSocketConnected(false);
          console.error('WebSocket connection error:', error);
        },
      );
    } catch (error) {
      console.error('Error connecting to WebSocket:', error);
    }
  };

  const onReceiveDriverLocation = useCallback((payload) => {
    if (!payload) return;

    try {
      const driverData = JSON.parse(payload.body);
      setData((prevData) => {
        const updatedData = prevData.map((item) => (item.driverId === driverData.driverId ? driverData : item));

        const isNewDriver = !prevData.some((item) => item.driverId === driverData.driverId);
        return isNewDriver ? [...updatedData, driverData] : updatedData;
      });
    } catch (error) {
      console.error('Error parsing WebSocket data:', error);
    }
  }, []);

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSearchClick = () => {
    setFilterValue(searchValue);
    setCurrentPage(1);
  };

  return (
    <>
      <Row gutter={[20, 20]}>
        <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
          <TitleLevel3 label={'Active Drivers'} />
        </Col>
        <Col span={12} style={{ textAlign: 'end' }}>
          <Link to={CustomPath.driver_location_map}>
            <NJVButton type="primary">Live Map</NJVButton>
          </Link>
        </Col>
        <Col span={6}>
          <NJVInput
            placeholder="Search By Name or VehiclePlateNumber"
            value={searchValue}
            onChange={handleSearchChange}
            bgcolor="#f1f1f1"
            style={{ width: '100%' }}
          />
        </Col>
        <Col span={18}>
          <NJVButton onClick={handleSearchClick} type="primary">
            Search
          </NJVButton>
        </Col>
        {dataFetching ? (
          <Skeleton />
        ) : (
          <>
            {actualData.length > 0 ? (
              <>
                {actualData.map((driver) => (
                  <Col key={driver.driverId} span={8}>
                    <NJVCard
                      cardBgColor={'#ffffff'}
                      bordered={false}
                      cardshadow="0 2px 12px rgba(0, 0, 0, 0.1)"
                      style={{ borderRadius: 12 }}
                    >
                      <Row>
                        <Col span={14}>
                          <div style={{ display: 'flex', alignItems: 'center', height: '45px' }}>
                            <Avatar
                              src={driver?.driverPhotoUrl ? driver?.driverPhotoUrl : defaultProfile}
                              style={{ width: '40px', height: '40px', marginRight: '8px', borderColor: '#bfbbbb' }}
                            />
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <span style={{ fontWeight: 'bold', fontSize: '16px', lineHeight: '1' }}>
                                {driver.driverName}
                                <span style={{ marginLeft: 7, color: '#bfbbb1', fontSize: '15px', marginTop: 2 }}>
                                  {driver.driverNumber}
                                </span>
                              </span>
                              <span style={{ fontSize: '15px', lineHeight: '1.2', fontWeight: [500] }}>
                                {driver?.vehiclePlateNumber ? driver?.vehiclePlateNumber : 'No connected vehicle'}{' '}
                                <span style={{ fontSize: '15px', color: 'gray' }}>{driver?.vehicleModal}</span>
                              </span>
                            </div>
                          </div>
                        </Col>
                        <Col span={10} style={{ textAlign: 'end' }}>
                          {driver.driverStatus === 'Available' ? (
                            <Tag style={{ borderRadius: '10px', padding: '2px 11px' }} color="#30c943">
                              Available
                            </Tag>
                          ) : driver.driverStatus === 'Offline' ? (
                            <Tag style={driverStatusTagStyle} color={Theme.colors.gray}>
                              Offline
                            </Tag>
                          ) : driver.driverStatus === 'Idle' ? (
                            <Tag style={driverStatusTagStyle} color={Theme.colors.purple}>
                              Idle
                            </Tag>
                          ) : driver.driverStatus === 'Inactive' ? (
                            <Tag style={driverStatusTagStyle} color="#d1bf75">
                              Inactive
                            </Tag>
                          ) : driver.driverStatus === 'Busy' ? (
                            <Tag style={driverStatusTagStyle} color={Theme.colors.light_blue}>
                              Busy
                            </Tag>
                          ) : null}
                        </Col>
                      </Row>
                    </NJVCard>
                  </Col>
                ))}
                <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Pagination
                    current={currentPage}
                    pageSize={pageSize}
                    total={filteredData.length}
                    onChange={(page) => setCurrentPage(page)}
                    style={{ textAlign: 'end', marginTop: '20px' }}
                  />
                </Col>
              </>
            ) : (
              <Col span={24} style={{ textAlign: 'center', justifyContent: 'center' }}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </Col>
            )}
          </>
        )}
      </Row>
    </>
  );
};

export default ActiveDriverList;
