import { EditOutlined } from '@ant-design/icons';
import { Button, Col, Row, Skeleton, Space, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import getTableColumns, {
  getRowClassName,
  NJVAddButton,
  NJVButton,
  NJVInput,
  NJVTable,
  TitleLevel3,
} from '../../../component/core-component';
import { Constant, UserStatus } from '../../../core/constant';
import Api from '../../../network/api';
import { HTTP_METHOD } from '../../../network/httpMethod';
import { MEDIA_TYPE } from '../../../network/mediaType';
import { ApiHandler } from '../../../network/network-manager';
import CustomPath from '../../../route/custom-path';

function DriverPage() {
  const pageSize = Constant.pageSize;
  const type = 'DRIVER';
  const [page, setPage] = useState(0);
  const [totalPagination, setTotalPagination] = useState(0);
  const [dataFetching, setDataFetching] = useState(false);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState();

  const handleSearch = () => {
    fetchData();
  };

  const fetchData = (pageNumber) => {
    const requestParams = {
      size: pageSize,
      page: pageNumber ? pageNumber - 1 : page,
      fullName: filterData?.searchFullName?.trim() ? filterData?.searchFullName?.trim() : '',
      phoneNumber: filterData?.searchPhoneNumber?.trim() ? filterData?.searchPhoneNumber?.trim() : '',
    };

    ApiHandler({
      url: Api.driver_filter,
      method: HTTP_METHOD.GET,
      MediaType: MEDIA_TYPE.JSON,
      requestParams,
    })
      .then((response) => {
        setPage(pageNumber ? pageNumber - 1 : page);
        setTotalPagination(response?.totalElements);
        setData(response?.content);
      })
      .catch(() => {})
      .finally(() => setDataFetching(false));
  };

  useEffect(() => {
    setDataFetching(true);
    fetchData();
  }, []);

  const onChangeFilter = (key, value) => {
    setFilterData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const columns = [
    {
      title: 'No.',
      key: 'index',
      render: (value, item, index) => <span>{page * pageSize + index + 1}</span>,
      width: 5,
    },
    {
      title: 'Name',
      key: 'fullName',
      dataIndex: 'fullName',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'License Info',
      dataIndex: 'driverLicenseInfo',
      key: 'driverLicenseInfo',
    },
    {
      title: 'Status',
      key: 'status',
      render: (_, { status }) => (
        <>
          {status === 'Active' ? (
            <Tag color="#87d068">{UserStatus[status]}</Tag>
          ) : (
            <Tag color="#cd201f">{UserStatus[status]}</Tag>
          )}
        </>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: '',
      textAlign: 'center',
      width: 60,
      render: (driver) => (
        <Space>
          <Link to={`${CustomPath.driver_update}/${driver.id}`}>
            <Button size={'middle'} type="primary" shape="circle">
              <EditOutlined />
            </Button>
          </Link>
        </Space>
      ),
    },
  ];

  const tableColumns = getTableColumns(columns, type);

  const handlePaginationChange = (pageNumber) => {
    fetchData(pageNumber);
  };

  return (
    <>
      <Row style={{ alignItems: 'center' }} gutter={[20, 20]}>
        <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
          <TitleLevel3 label={'Driver'} />
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <Link to={CustomPath.driver_form}>
            <NJVAddButton type={type} />
          </Link>
        </Col>
        <Col span={6}>
          <NJVInput
            bgcolor="#f1f1f1"
            placeholder="Search by DriverName"
            onChange={(e) => onChangeFilter('searchFullName', e.target.value)}
          />
        </Col>
        <Col span={6}>
          <NJVInput
            bgcolor="#f1f1f1"
            placeholder="Search by PhoneNumber"
            onChange={(e) => onChangeFilter('searchPhoneNumber', e.target.value)}
          />
        </Col>
        <Col span={3}>
          <NJVButton type="primary" style={{ width: '100%' }} onClick={handleSearch}>
            Search
          </NJVButton>
        </Col>
        <Col span={24}>
          {dataFetching ? (
            <Skeleton active />
          ) : (
            <NJVTable
              rowKey={'id'}
              style={{ borderRadius: 10 }}
              pagination={{
                position: 'top' | 'bottom',
                total: totalPagination,
                current: page + 1,
                onChange: handlePaginationChange,
                defaultPageSize: pageSize,
                showSizeChanger: false,
              }}
              columns={tableColumns}
              dataSource={data}
              rowClassName={getRowClassName}
            />
          )}
        </Col>
      </Row>
    </>
  );
}
export default DriverPage;
