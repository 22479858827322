import { Button, Col, Row, Skeleton, Table } from 'antd';
import { useEffect, useState } from 'react';
import { Constant } from '../../core/constant';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { ApiHandler } from '../../network/network-manager';
const getRowClassName = (record, index) => {
  return index % 2 === 0 ? 'blue-row' : 'black-row';
};

const NotificationUnSubcribersList = (props) => {
  const pageSize = 4;
  // const pageSize = Constant.pageSize;

  const [page, setPage] = useState(0);
  const [totalPagination, setTotalPagination] = useState(0);
  const [data, setData] = useState([]);

  const [fetching, setFetching] = useState(false);
  const [addingUser, setAddingUser] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useEffect(() => {
    fetchUnSubscriptList();
  }, [props]);

  const fetchUnSubscriptList = (pageNumber) => {
    setFetching(true);
    const requestParams = {
      page: pageNumber ? pageNumber - 1 : 0,
      size: pageSize,
      userType: props.userType,
    };
    ApiHandler({
      url: Api.notification_channel_get_unsubscribe_users,
      method: HTTP_METHOD.GET,
      specificId: props.channelId,
      requestParams,
    })
      .then((response) => {
        const temp = response?.content?.map((item) => ({ ...item, key: item.id }));
        setData(temp);
        setPage(pageNumber ? pageNumber - 1 : 0);
        setTotalPagination(response?.totalElements);
      })
      .catch(() => { })
      .finally(() => setFetching(false));
  };

  const addUsersToChannel = () => {
    setAddingUser(true);
    const requestData = {
      channelId: props.channelId,
      userIds: selectedRowKeys,
    };

    ApiHandler({
      url: Api.notification_channel_add_members,
      method: HTTP_METHOD.POST,
      requestData,
      disableShowMessage: true,
    })
      .then(() => {
        setAddingUser(false);
        notificaitonChannelOnAddUsers();
        setSelectedRowKeys([]);
        fetchUnSubscriptList();
      })
      .catch(() => { })
      .finally(() => setAddingUser(false));
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const handlePaginationChange = (pageNumber) => {
    fetchUnSubscriptList(pageNumber)
  };

  const notificaitonChannelOnAddUsers = () => {
    const { onAddedUsers } = props;
    if (onAddedUsers) {
      onAddedUsers();
    }
  };

  const columns = [
    {
      title: 'No.',
      render: (value, item, index) => <span>{page * pageSize + index + 1}</span>,
      width: 5,
    },
    {
      title: 'Name',
      dataIndex: 'fullName',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
    },
  ];
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        {fetching ? (
          <Skeleton active />
        ) : (
          <Table
            rowSelection={{
              selectedRowKeys,
              onChange: onSelectChange,
            }}
            style={{ borderRadius: 10 }}
            pagination={{
              position: 'top' | 'bottom',
              total: totalPagination,
              current: page + 1,
              defaultPageSize: pageSize,
              showSizeChanger: false,
              onChange: handlePaginationChange,
            }}
            columns={columns}
            dataSource={data}
            rowClassName={getRowClassName}
          />
        )}
      </Col>
      <Col span={24}>
        <Button
          type="primary"
          disabled={selectedRowKeys?.length === 0}
          onClick={() => addUsersToChannel()}
          loading={addingUser}
        >
          Add to channel
        </Button>
      </Col>
    </Row>
  );
};

export default NotificationUnSubcribersList;
