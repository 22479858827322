import { notification } from 'antd';

const prefix = 'api/v1/';
const Api = {
  host: process.env.REACT_APP_ZEIMM_MAIN_SERVER_URL,
  driver_location_host: process.env.REACT_APP_ZEIMM_LOCATION_SERVER_URL,

  enum_filter: prefix + 'enum/filter',

  user_update_device_token: prefix + 'user/update-device-token',

  admin: prefix + 'admin',
  admin_filter: prefix + 'admin/filter',

  role: prefix + 'role',
  role_filter: prefix + 'role/filter',
  role_menus: prefix + 'enum/filter?enumType=MENU',
  townships: prefix + 'enum/filter?enumType=TOWNSHIP',
  payment_methods: prefix + 'enum/filter?enumType=PAYMENT_METHOD',

  driver: prefix + 'driver',
  driver_filter: prefix + 'driver/filter',
  driverAssign: prefix + 'driver/assign',
  drivers_all: prefix + 'driver/find-all',
  driver_points_all: prefix + 'driver/find-histories',
  update_driver_points: prefix + 'driver/update-points',
  driver_profile_upload: prefix + 'driver/upload',
  driver_get_unassigned_list_by_date: prefix + 'driver/get-unassigned-drivers-by-date',
  driver_get_assigned_list_by_date: prefix + 'driver/get-assigned-drivers-by-date',

  assign_driver_to_schedule_trip: prefix + 'trip/admin/assign-driver-to-schedule-trip',

  passenger: prefix + 'passenger',
  passenger_filter: prefix + 'passenger/filter',

  trip_history: prefix + 'trip/admin',
  trip_history_update_fare: prefix + 'trip/admin/update-trip-fare',
  trip_history_filter: prefix + 'trip/admin/filter',
  trip_history_events: prefix + 'trip/admin/events',
  trip_create_assign: prefix + 'trip/admin/trip-assign',
  update_trip_assign: prefix + 'trip/admin/update-trip-assign',
  trip_dispatcher: prefix + 'trip/admin/trips',
  trip_complaints: prefix + 'trip/admin/complaints',
  trip_complaints_filter: prefix + 'trip/admin/complaints/filter',
  trip_complaints_by_id: prefix + 'trip/admin/complaints/get-by-trip-id',
  trip_count: prefix + 'trip/admin/count-trip',
  trip_instant_assign_driver_to_trip: prefix + 'trip/admin/instant-assign-driver-to-trip',

  vehicle: prefix + 'vehicle',
  vehicle_filter: prefix + 'vehicle/filter',
  vehicle_get_unassigned_list_by_date: prefix + 'vehicle/get-unassigned-vehicles-by-date',
  vehicle_get_assigned_list_by_date: prefix + 'vehicle/get-assigned-vehicles-by-date',

  region: prefix + 'region',
  region_filter: prefix + 'region/filter',
  regions_all: prefix + 'region/find-all',

  zone: prefix + 'zone',
  zone_filter: prefix + 'zone/filter',
  zone_find_by_region: prefix + 'zone/find-zones-by-region',
  zone_pricing: prefix + 'zone-pricing',
  zone_pricing_filter: prefix + 'zone-pricing/filter',

  faq: prefix + 'faq',
  faq_filter: prefix + 'faq/filter',
  faq_all: prefix + 'faq/find-all',
  delete_faq: prefix + 'faq/delete',

  promotion: prefix + 'promotion',
  promotion_filter: prefix + 'promotion/filter',
  promotion_all: prefix + 'promotion/find-all',
  delete_promotion: prefix + 'promotion/delete',
  promotion_usage_history: prefix + 'promotion/promotion-history',

  sos: prefix + 'sos',
  sos_filter: prefix + 'sos/filter',
  sos_pending_count: prefix + 'sos/pending',

  charging_station: prefix + 'charging-station',
  charging_station_filter: prefix + 'charging-station/filter',
  charging_station_all: prefix + 'charging-station/find-all',
  charging_station_delete: prefix + 'charging-station/delete',
  charging_station_update_electricity: prefix + 'charging-station/update-electricity-info',

  notification_channel: prefix + 'notification_channel',
  notification_channel_filter: prefix + 'notification_channel/filter',
  notification_history_filter: prefix + 'notification_history/filter',
  notification_push_by_topic: prefix + 'notification/topic',
  notification_channel_get_unsubscribe_users: prefix + 'notification_channel/user/unsubscribe-list',
  notification_channel_get_subscribe_users: prefix + 'notification_channel/user/subscribe-list',
  notification_channel_add_members: prefix + 'notification_channel/add-members-to-channel',
  notification_channel_remove_members: prefix + 'notification_channel/remove-members-from-channel',

  reset_multiple_points: prefix + 'driver/reset-multiple-points',

  login: prefix + 'public/admin/login',

  refresh_token: prefix + 'public/refresh-token',

  base_fare_rule: prefix + 'base-fare-rule',
  base_fare_rule_delete: prefix + 'base-fare-rule/delete',
  base_fare_rule_filter: prefix + 'base-fare-rule/filter',

  fare_rule: prefix + 'fare-rule',
  fare_rule_create_all: prefix + 'fare-rule/createAll',
  fare_rule_delete: prefix + 'fare-rule/delete',
  fare_rule_filter: prefix + 'fare-rule/filter',
  fare_rule_by_fare_type: prefix + 'fare-rule/find-by-fare-type',

  peak_hour_setup: prefix + 'peak-hour-rule',
  peak_hour_delete: prefix + 'peak-hour-rule/delete',
  peak_hour_setup_filter: prefix + 'peak-hour-rule/filter',

  weather_condition_setup: prefix + 'weather-charges-rule',
  weather_condition_delete: prefix + 'weather-charges-rule/delete',
  weather_condition_setup_filter: prefix + 'weather-charges-rule/filter',

  driver_location: prefix + 'driver-location/filter',

  chat_messages_by_trip_id: prefix + 'chatroom/get-messages-by-trip-id',

  fare_calculate: prefix + 'trip/admin/calculate/fixed-fare',

  daily_income_history: prefix + 'trip-income/daily-income-history',
  daily_income_summary: prefix + 'trip-income/daily-income-summary',
  daily_income_collect: prefix + 'trip-income/admin/collect-by-admin',

  landing_page_report: prefix + 'trip/admin/landing-page-report',

  core_config: prefix + 'core-config',

  trip_excel_export: prefix + 'trip/admin/download-excel',
  vehicle_export_export: prefix + 'report/excel/vehicles',

  heat_map: prefix + 'heatmap/get-all-data',
};

export default Api;
