const Theme = {
  colors: {
    default: '#1677ff',
    primary: '#3fcca4',
    success: '#28a745',
    card_bg_color: '#f5f5f5',
    system_blue: '#4096ff',
    light_blue: '#2db7f5',
    gray: '#757575',
    light_gray: '#f5f5f5',
    light_gray_2: '#f9f9f9',
    red: '#FF0000 ',
    green: '#008000',
    soft_green: '#52c41a',
    orange: '#FFA500',
    purple: '#6A0DAD',
    red_orange: '#f56c1d',
  },
};
export default Theme;
